import {normalizedType, Normalizer} from "./normalizer";
import {CustomeoCompany} from "../../model/CustomeoCompany";

const CustomeoCompanyNormalizer = new class implements Normalizer<CustomeoCompany>{
    normalize(data: normalizedType<CustomeoCompany>): CustomeoCompany {

        return new CustomeoCompany(
            data.id,
            data.name
        );
    }
}();

export { CustomeoCompanyNormalizer };
