import * as React from "react";
import {useState} from "react";
import {getDefaultActiveKey, Tabs, TabsProps} from "./Tabs";
import {Tab} from "react-bootstrap";
import classNames from "classnames";

export interface CreatableTabsProps extends Omit<TabsProps, 'onSelect' | 'activeKey'> {
    allowAdd?: boolean
    /** set to true indicates that the add button should be disabled and display a spinner */
    isAdding?: boolean
    /** return the key to set as active */
    onAdd: () => string | number | Promise<string | number>
}

const AddTabTitle = ({loading}: {loading: boolean}) => {
    return (
        <>
            <i className={classNames(loading ? 'spinner-border text-white spinner-border-sm' : 'fas fa-plus', 'me-1')}></i>
            Ajouter
        </>
    )
}

const addEventKey = '__add__';

export const CreatableTabs = (props: CreatableTabsProps) => {

    const {
        allowAdd = true,
        isAdding = false,
        children,
        defaultActiveKey,
        onAdd,
        ...tabProps
    } = props;

    const [key, setKey] = useState(defaultActiveKey ?? getDefaultActiveKey(children));

    const triggerOnAdd = async (eventKey: string | null) => {
        if (eventKey) {
            if (eventKey === addEventKey) {
                setKey(await onAdd());
                return;
            }
            setKey(eventKey);
        }
    }

    return (
        <Tabs {...tabProps} onSelect={triggerOnAdd} activeKey={key}>
            {children}
            {
                allowAdd
                    ? (
                        <Tab eventKey={addEventKey} title={<AddTabTitle loading={isAdding}/>} disabled={isAdding}/>
                    ) : null
            }
        </Tabs>
    )
}
