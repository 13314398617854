import React, {useEffect, useState} from "react";
import {Tabs} from "../../../components/Tabs/Tabs";
import {useParams} from "react-router-dom";
import {Ticket} from "../../../model/Ticket";
import useDatasource from "../../../hooks/useDatasource";
import {TicketNormalizer} from "../../../services/normalizer/ticketNormalizer";
import CommentTab from "../../../components/app/App/ImportTab/CommentTab";
import ChatTab from "../../../components/app/App/ImportTab/ChatTab";
import DossierTab from "../../../components/app/App/ImportTab/DossierTab";
import {Tab} from "react-bootstrap";
import CustomsTab from "../../../components/app/App/ImportTab/CustomsTab";
import FileTab from "../../../components/app/App/ImportTab/FileTab";

export default function ImportType() {
    const {id} = useParams();
    const [ticket, setTicket] = useState<Ticket | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const {get} = useDatasource<Ticket>('workflow_tickets', TicketNormalizer);

    useEffect(() => {
        const idInt = parseInt(id || '');

        const controller = new AbortController();

        (async () => {
            if (!isNaN(idInt)) {
                try {
                    const ticket = await get(
                        idInt, controller
                    )

                    setTicket(ticket);
                    setIsLoading(false);
                } catch (err) {
                    console.log(err);
                }
            }
        })();

        return () => {
            controller.abort();
        }
    }, [id, get]);

    const name = typeof ticket?.shipment.client === 'string' ? '' : ticket?.shipment.client.name;

    return <>
        <h1>
            {
                isLoading
                    ? ''
                    : (
                        (ticket?.shipment.derudderReference ?? 'Dossier à créer dans FF')
                        + ' - '
                        + (name || '')
                    )
            }
        </h1>

        <Tabs mountOnEnter={true}>
            <Tab eventKey="shipment" title="Dossier">
                <DossierTab ticket={ticket}/>
            </Tab>
            <Tab eventKey="comment" title="Notes internes">
                <CommentTab ticket={ticket}/>
            </Tab>
            <Tab eventKey="chat" title="Chat">
                <ChatTab shipment={ticket?.shipment ? ticket.shipment : null}/>
            </Tab>
            <Tab eventKey="file" title="Documents">
                <FileTab shipment={ticket?.shipment ? ticket.shipment : null}/>
            </Tab>
            <Tab eventKey="my_customs" title="MyCustoms">
                <CustomsTab shipment={ticket?.shipment ? ticket.shipment : null}/>
            </Tab>
        </Tabs>
    </>;
}
