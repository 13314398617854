import React from "react";
// import PageTitle from "../../components/PageTitle";
import {FlashContextProvider} from "../../context/FlashContext";
import AlertHandler from "../../components/AlertHandler";
// import {useParams} from "react-router-dom";
// import bgDerudder from "../../assets/images/bg-derudder.png";

type Props = {
    children: React.ReactNode;
}

function Panel({children}: Props) {
    /*const [isParams, setIsParams] = React.useState<boolean>(false);
    const {id} = useParams();

    React.useEffect(() => {
        id ? setIsParams(true) : setIsParams(false);
    }, [id]);*/

    return (
        <div className="bg-transparent d-flex flex-column flex-fill overflow-hidden">
            {/*{
                isParams ?
                    <PageTitle/>
                    : null
            }*/}
            <div className="bg-transparent d-flex flex-column flex-fill overflow-hidden">
                <FlashContextProvider>
                    <AlertHandler/>
                    {children}
                </FlashContextProvider>
            </div>
        </div>
    );
}

export default Panel;
