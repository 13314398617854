import {Col, Row} from "react-bootstrap";
import {Ticket} from "../../../../../model/Ticket";
// import ButtonComponent from "../../../../../components/ButtonComponent";
import React from "react";
import {renderFormProps} from "../../../../FormController";
import {ControlImportActions, DossierImportActions} from "../../../../../components/app/App/DossierImportActions";
import {ControlSwitchInput} from "../../../../../components/SwitchButton";
import ConditionalInput from "../../../../fields/ConditionalInput/ConditionalInput";
import DateInput from "../../../../fields/DateInput";
import SelectInput, {SelectInputOptions} from "../../../../fields/SelectInput";
import TextInput from "../../../../fields/TextInput";
import {ShipmentPaletteSizeConstants, ShipmentPaletteTypeConstants} from "../../../../../constants/model";
import {formatDate} from "../../../../../utils";

interface UnloadingProps extends renderFormProps<Ticket> {
    resource: Ticket
}

export const Unloading = ({register, control, resource}: UnloadingProps) => {
    const shipment = resource.shipment;

    const actions: ControlImportActions[] = [
        {label: 'Commande de traction ?', name: 'requestTraction', value: resource.requestTraction},
        {label: 'Commande de dépotage ?', name: 'unloadingCommand', value: resource.unloadingCommand},
        {label: 'Visite de douane ?', name: 'customsVisit', value: resource.customsVisit, switchOnChildren: [
                {label: 'Date de visite de douane', name: 'customsVisitDate', value: resource.customsVisitDate, type: "date"}
            ]
        },
        {label: 'Feu vert douane ?', name: 'customsGreenLights', value: resource.customsGreenLights},
        {label: 'Contrôle marchandise ?', name: 'wareControl', value: resource.wareControl},
        {label: "Retour d'analyse ?", name: 'returnAnalysis', value: resource.returnAnalysis, switchOnChildren: [
                {label: 'Date de retour d\'analyse', name: 'returnAnalysisDate', value: resource.returnAnalysisDate, type: "date"}
            ]
        },
        {label: 'Dépotage terminé ?', name: 'unloadingDone', value: resource.unloadingDone},
        {label: 'Demande de retour terminal ?', name: 'terminalReturnRequest', value: resource.terminalReturnRequest},
        {label: 'Rapport de dépotage ?', name: 'unloadingReport', value: resource.unloadingReport, switchOnChildren: [
                {label: 'Date de réception du rapport', name: 'unloadingReportReceivedDate', value: resource.unloadingReportReceivedDate, type: "date"}
            ]
        },
        {
            label: 'Commande de chargement ?',
            name: 'isLoadingCommand',
            value: resource.isLoadingCommand,
            switchOnChildren: [
                {
                    name: 'loadingCommand',
                    value: resource.loadingCommand ?? null,
                    type: 'date'
                }
            ],
            type: 'bool'
        },
    ];

    const paletteSizeTypeOptions: SelectInputOptions[] = [];

    paletteSizeTypeOptions.push(
        {
            label: ShipmentPaletteSizeConstants.SIZE_80x100 + ' - ' +
                ShipmentPaletteTypeConstants.HACHETTE.toUpperCase(),
            value: ShipmentPaletteSizeConstants.SIZE_80x100 + ' - ' +
                ShipmentPaletteTypeConstants.HACHETTE.toUpperCase(),
        },
        {
            label: ShipmentPaletteSizeConstants.SIZE_80x120 + ' - ' +
                ShipmentPaletteTypeConstants.EXCHANGE.toUpperCase(),
            value: ShipmentPaletteSizeConstants.SIZE_80x120 + ' - ' +
                ShipmentPaletteTypeConstants.EXCHANGE.toUpperCase(),
        },
        {
            label: ShipmentPaletteSizeConstants.SIZE_80x120 + ' - ' +
                ShipmentPaletteTypeConstants.LOST.toUpperCase(),
            value: ShipmentPaletteSizeConstants.SIZE_80x120 + ' - ' +
                ShipmentPaletteTypeConstants.LOST.toUpperCase(),
        },
        {
            label: ShipmentPaletteSizeConstants.SIZE_100x120 + ' - ' +
                ShipmentPaletteTypeConstants.LOST.toUpperCase(),
            value: ShipmentPaletteSizeConstants.SIZE_100x120 + ' - ' +
                ShipmentPaletteTypeConstants.LOST.toUpperCase(),
        },
    );

    return (
        <Row className={"border-bottom ms-0 me-0 pt-3 pb-5"}>
            <Col>

                <h2 className={'d-flex align-items-center col'}>
                    <span>Dépotage</span>
                    <ControlSwitchInput
                        name={'shipment.destuffing'}
                        control={control}
                        style={{display: 'inline-block', scale: '0.5'}}
                    />
                </h2>

                <ConditionalInput
                    control={control}
                    fieldName={'shipment.destuffing'}
                    renderField={() => (
                        <Row>
                            <Col lg={8}>
                                <Row>
                                    <Col lg={4}>
                                        <div>
                                            <TextInput
                                                label={'Entrepôt'}
                                                name={'shipment.destuffingWarehouse'}
                                                control={control}
                                            />
                                        </div>
                                        <div>
                                            {/* readonly sur fichier correspondance */}
                                            <p className={'label'}>
                                                Date prévue de traction
                                            </p>
                                            <p>
                                                {shipment.tractionEstimatedDate
                                                    ? formatDate(
                                                        shipment.tractionEstimatedDate,
                                                        'fr-FR',
                                                        {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit"
                                                        })
                                                    : '-'
                                                }
                                            </p>
                                        </div>
                                        <div>
                                            {/* readonly sur fichier correspondance */}
                                            <p className={'label'}>
                                                Date réelle de traction
                                            </p>
                                            <p>
                                                {shipment.tractionRealDate
                                                    ? formatDate(
                                                        shipment.tractionRealDate,
                                                        'fr-FR',
                                                        {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit"
                                                        })
                                                    : '-'
                                                }
                                            </p>
                                        </div>
                                        <div>
                                            <p className={'label'}>Nom du tractionnaire</p>
                                            <p>{shipment.tractionOperator}</p>
                                        </div>
                                        <div>

                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <TextInput
                                                label={'Numéro de FE'}
                                                name={'shipment.destuffingFeNumber'}
                                                control={control}
                                            />
                                        </div>
                                        <div>
                                            <DateInput
                                                label={'Date prévue de dépotage'}
                                                name={'shipment.destuffingPlannedDate'}
                                                control={control}
                                                type={'date'}
                                            />
                                        </div>
                                        <div>
                                            <DateInput
                                                label={'Date de fin de dépotage'}
                                                name={'shipment.destuffingEndDate'}
                                                control={control}
                                                type={'date'}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div>
                                            <TextInput
                                                label={'Numéro BBI'}
                                                name={'shipment.destuffingIcvNumber'}
                                                control={control}
                                            />
                                        </div>
                                        <div>
                                            <ControlSwitchInput
                                                label={'Palettisation ?'}
                                                name={'palettisation'}
                                                control={control}
                                                value={!!resource.paletteSizeAndType}
                                                style={{display: 'inline-block'}}
                                            />
                                            <ConditionalInput
                                                control={control}
                                                fieldName={'palettisation'}
                                                renderField={
                                                    () => <div className={`mt-1`}>
                                                        <SelectInput
                                                            label={'Taille et type de palettes'}
                                                            name={'paletteSizeAndType'}
                                                            control={control}
                                                            selectOptions={paletteSizeTypeOptions}
                                                            defaultValue={
                                                                resource.paletteSizeAndType ?
                                                                    {
                                                                        label: resource.paletteSizeAndType,
                                                                        value: resource.paletteSizeAndType
                                                                    }
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                }
                                                fieldValue={true}
                                                defaultValue={resource.palettisation}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </Col>

                            <Col lg={4}>
                                {
                                    DossierImportActions(actions, register, control)
                                }
                            </Col>

                        </Row>
                    )}/>
            </Col>
        </Row>
    )
}
