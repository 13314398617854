import {User} from "./User";

export default abstract class AbstractComment {
    createdBy: User | null = null
    createdAt: Date = new Date()

    constructor(
        public id: number = 0,
        public comment: string | null = null
    ) {
    }
}