import React, {ReactElement} from "react";
import {Alert, Table} from 'react-bootstrap';
import {renderListingProps} from '../ListingController';
import {Identifiable} from '../../model/interface/Identifiable';
import {formatDate} from '../../utils';
import Spinner from "../Spinner";

export interface ResourceListingActionProps<ResourceT extends Identifiable> {
    resource: ResourceT
    deleteResource: (data: ResourceT) => void
}

interface ColumnRelationProps<ResourceR extends Identifiable> {
    property: Extract<keyof ResourceR, string>
    model: ResourceR
}

export interface ListingProps<ResourceT extends Identifiable> extends renderListingProps<ResourceT> {
    columns: Array<{
        label: string
        property: Extract<keyof ResourceT, string>,
        type?: string
        relation?: ColumnRelationProps<any>
    }>,
    tableStyles?: {
        theadClasses?: string,
        tbodyClasses?: string,
        stripe?: boolean,
        bordered?: boolean,
        hover?: boolean
    },
    actions?: {
        label: string,
        renderActions: (props: ResourceListingActionProps<ResourceT>) => ReactElement;
    },
    paginated?: boolean
}
export default function TableListing<ResourceT extends Identifiable>({...props}: ListingProps<ResourceT>) {

    const deleteResource = () => {}

    return !props.isLoading ?
        <>
            <Table striped={props.tableStyles?.stripe} bordered={props.tableStyles?.bordered} hover={props.tableStyles?.hover} className={'bg-white mt-1'}>
                <thead className={props.tableStyles?.theadClasses}>
                <tr>
                    {
                        props.columns.map(({label, property}, index) =>
                            <th key={index}>{label}</th>
                        )
                    }
                    {
                        props.actions ?
                            <th>Actions</th>
                            : null
                    }
                </tr>
                </thead>
                <tbody className={props.tableStyles?.tbodyClasses}>
                {
                    props.resources.map((resource: any) =>
                        <tr key={resource.id}>
                            {
                                props.columns.map((column, index) =>
                                    <td key={index}>
                                        {
                                            column.relation ?
                                                resource[column.property]?.map((relation: any, index: number) => (
                                                    <React.Fragment key={index}>
                                                        <span className={'badge bg-primary text-light'}>{relation[(column.relation?.property || '')].toString()}</span>
                                                        &#8239;
                                                    </React.Fragment>
                                                ))
                                            : resource[column.property] !== undefined ?
                                                resource[column.property] !== null ?
                                                    column.type && column.type === 'date' ?
                                                        `${formatDate(
                                                            new Date(resource[column.property]), 'fr-FR',
                                                            {},
                                                            'date',
                                                        )}`
                                                    : typeof resource[column.property] == 'boolean' ?
                                                        resource[column.property] ?
                                                            (<span className={'badge bg-success text-light'}>
                                                                <i className={'fas fa-check'}></i>
                                                            </span>)
                                                        :
                                                            (<span className={'badge bg-danger text-light'}>
                                                                <i className={'fas fa-times'}></i>
                                                            </span>)
                                                    : resource[column.property] instanceof Date ?
                                                        `${formatDate(
                                                            resource[column.property],
                                                            'fr-FR',
                                                            {year: 'numeric', day: '2-digit', month: 'short'},
                                                            'date'
                                                        )} 
                                                        à 
                                                        ${formatDate(
                                                            resource[column.property],
                                                            'fr-FR',
                                                            {},
                                                            'time'
                                                        )}`
                                                        : resource[column.property] instanceof Array ?
                                                            resource[column.property].map((property: any, index: number) => (
                                                                    <React.Fragment key={index}>
                                                                        <span className={'badge bg-primary text-light'}>{property.toString()}</span>
                                                                        &#8239;
                                                                    </React.Fragment>
                                                                )
                                                            )
                                                        : resource[column.property]?.toString()
                                                    : null
                                            : null
                                        }
                                    </td>
                                )
                            }
                            {
                                props.actions ?
                                    <td>{props.actions.renderActions({deleteResource,resource})} </td>
                                    : null
                            }
                        </tr>
                    )
                }
                </tbody>
            </Table>
            {
                props.resources.length === 0
                    ? <Alert variant={"info"}>Aucun Résultat</Alert>
                    : null
            }
        </>
    : <div className="text-center pt-2 mb-0">
            <Spinner/>
        </div>
}