import React from 'react';

import {routes} from "../../navigations/route/main";
import MainMenu from "../Menu/MainMenu";
import {RoutesProps, filterRoutes} from "../../navigations/router.config";

const LeftSidebar = () => {
    const [root] = routes;
    const mainRouteChildren: RoutesProps[] = [];
    root.children && root.children.forEach((value, index) => {
        if (value.position === 0) mainRouteChildren.push(value);
    })
    return (
        <React.Fragment>
            <div className="left-side-menu bg-primary">
                <nav style={{maxHeight: '100%'}} className={"mb-1"}>
                    <MainMenu routes={filterRoutes(mainRouteChildren)}/>
                </nav>
            </div>
        </React.Fragment>
    );
};

export default LeftSidebar;