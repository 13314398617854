import logo from '../../assets/images/logo-siqual.svg';
function Footer() {

    return (
        <footer className="footer bg-white">
            <div className="container-fluid d-flex">
                <div className={'d-flex align-items-center'}>
                    <p className={'mb-0'}>
                        Copyright &copy;&nbsp;
                    </p>

                    <a href="https://www.siqual.fr"
                       className={'text-primary d-flex align-items-center'}
                       target={'_blank'} rel="noreferrer">
                        {/*<i className="siqualfont-siqual"></i>*/}
                        <img
                            alt="Logo Siqual"
                            src={logo}
                            width="15"
                        />
                        <span>&nbsp;Siqual</span>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;