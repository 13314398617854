import React from 'react';
import {Form, FormCheckProps} from 'react-bootstrap';
import {Control, Controller} from "react-hook-form";

interface CheckboxInputProps extends FormCheckProps {
    containerClass?: string,
    inputClasses?: string,
    label?: string,
    name: string,
    control: Control
}

const CheckboxInput = (props: CheckboxInputProps) => {

    const {
        name,
        label,
        containerClass,
        inputClasses,
        control,
        ...inputProps
    } = props

    const errors = {[name]: {message: ''}};

    return (
        <Form.Group className={containerClass}>

            <Controller
                name={name}
                control={control}
                defaultValue={props.checked}
                disabled={props.disabled}
                render={(
                    {
                        field: {onChange, name, value},
                    },
                ) => (
                    <Form.Check
                        type={'checkbox'}
                        label={label}
                        name={name}
                        className={inputClasses + ' form-check-primary'}
                        defaultChecked={value}
                        onChange={onChange}
                        value={value}
                        {...inputProps}
                    />
                )}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]['message']}
                </Form.Control.Feedback>
            ) : null}

        </Form.Group>
    );
};

export default CheckboxInput;
