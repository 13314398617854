import ButtonComponent from "./ButtonComponent";
import classNames from "classnames";
import React from "react";

export interface FormActionsProps {
    isLoading: boolean
    label?: string
}

export function FormActions({isLoading, label = 'Sauvegarder'}: FormActionsProps) {
    return (
        <div className={"sticky-bottom border-top bg-white"}>
            <ButtonComponent
                buttonLabel={label}
                buttonType={"submit"}
                buttonIcon={classNames(
                    isLoading ? "spinner-border text-white spinner-border-sm" : "fas fa-save"
                )}
                disabled={isLoading}
            />
        </div>
    )
}