import React from 'react';
import {RouterProvider} from "react-router-dom";
import Router from "./navigations/Router";

function App() {
    return (
        <RouterProvider router={Router} />
    );
}

export default App;
