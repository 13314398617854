import {routesPosition, RoutesProps} from "../router.config";
import React from "react";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate('/import/');
    })

    return (
        <div> </div>
    )
}

export const dashboardRoutes: RoutesProps = {
    path: '/',
    position: routesPosition.leftSideBar,
    name: 'Accueil',
    icon: 'fas fa-home',
    element: <Dashboard/>,
    index: true,
};
