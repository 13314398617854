import {Row} from "react-bootstrap";
import TableListing, {ListingProps} from "../../../../../components/TableListing/TableListing";
import {ShipmentCargaison} from "../../../../../model/ShipmentCargaison";
import {renderFormProps} from "../../../../FormController";
import {Ticket} from "../../../../../model/Ticket";
import {useEffect, useState} from "react";
import useDatasource from "../../../../../hooks/useDatasource";
import {ShipmentCargaisonNormalizer} from "../../../../../services/normalizer/ShipmentCargaisonNormalizer";
import axios from "axios";

interface WareProps extends renderFormProps<Ticket> {}

const columns: ListingProps<ShipmentCargaison>["columns"] = [
    {label: 'N° TC', property: 'tcNumber'},
    {label: 'Plomb', property: 'lead'},
    {label: 'Taille', property: 'sizeName'},
    {label: 'Type', property: 'typeName'},
    {label: 'Nbr Colis', property: 'nbPackages'},
    {label: 'Unité', property: 'unitName'},
    {label: 'Poids', property: 'weight'},
    {label: 'Volume', property: 'volume'},
    {label: 'Dimensions', property: 'dimensions'},
    {label: 'Désignation marchandise', property: 'goodsDescription'},
    {label: 'C°', property: 'temperature'},
    {label: "Groupe d'emballage", property: 'packingGroup'},
    {label: 'Classe', property: 'class'},
    {label: 'Code ONU', property: 'codeOnu'},
]

const listingTableStyles = {
    theadClasses: 'small bg-light',
    tbodyClasses: 'small',
    noStripe: true
}

export const Ware = ({resource}: WareProps) => {

    const [cargoes, setCargoes] = useState<ShipmentCargaison[]>([]);
    const {index} = useDatasource<ShipmentCargaison>('shipment_cargos', ShipmentCargaisonNormalizer)

    useEffect(() => {
        const controller = new AbortController();

        index(
            {shipment: resource.shipment.id}, undefined, controller
        ).then(
            setCargoes
        ).catch(err => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        }
    }, [index, resource]);

    return (
        <Row className={'border-bottom ms-0 me-0 pt-3 pb-5'}>
            <h2>Marchandise</h2>
            <TableListing
                columns={columns}
                resources={cargoes}
                isLoading={false}
                tableStyles={listingTableStyles}
            />
        </Row>
    )
}
