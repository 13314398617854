import {normalizedType, Normalizer} from "./normalizer";
import {Nameable} from "../../model/interface/Nameable";

const NameableNormalizer = new class implements Normalizer<Nameable>{
    normalize(data: normalizedType<Nameable>): Nameable {

        return {
            id: data.id,
            name: data.name
        };
    }
}();

export { NameableNormalizer };
