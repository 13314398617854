import {normalizedType, Normalizer, serializedType} from "./normalizer";
import {WorkflowComment} from "../../model/WorkflowComment";

const WorkflowCommentNormalizer = new class implements Normalizer<WorkflowComment>{
    normalize(data: normalizedType<WorkflowComment>): WorkflowComment {
        const comment = new WorkflowComment(
            data.id,
            data.comment,
        );

        comment.createdBy = data.createdBy;
        comment.createdAt = new Date(data.createdAt);
        comment.workflowTicket = data.workflowTicket;

        return comment;
    }

    serialize(data: Partial<WorkflowComment>, context: any): serializedType<WorkflowComment> {
        switch (context) {
            case 'dossier':
                return {
                    comment: data.comment,
                    workflowTicket: data.workflowTicket,
                }
            default:
                return data;
        }
    }
}();

export { WorkflowCommentNormalizer };
