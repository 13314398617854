import {FileResource} from "./interface/FileResource";
import {FileType} from "../components/FileUpload/FilePreview";

export class ShipmentAttachedFile implements FileResource {
    id: number = 0
    attachedFile: null | string = null
    fileName: string = ''
    shipmentInvoice: null | string = null
    shipmentPacking: null | string = null
    shipmentQuotation: null | string = null
    shipmentDocument: null | string = null
    shipmentBlLta: null | string = null
    shipmentDau: null | string = null
    attachedFileMimeType: null | string = null

    file?: FileType

    constructor(id: number, fileName: string) {
        this.id = id;
        this.fileName = fileName;
    }

    get name() {
        return this.fileName
    }

    get url() {
        return this.attachedFile
    }

    get mime() {
        return this.attachedFileMimeType
    }
}
