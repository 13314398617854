import React, {useEffect, useState} from "react";
import {Shipment} from "../../../../model/Shipment";
import {ShipmentCustomeo} from "../../../../model/ShipmentCustomeo";
import useDatasource from "../../../../hooks/useDatasource";
import {ShipmentCustomeoNormalizer} from "../../../../services/normalizer/ShipmentCustomeoNormalizer";
import {Tab} from "react-bootstrap";
import Customeo from "../Customeo";
import {CreatableTabs} from "../../../Tabs/CreatableTabs";
import axios from "axios";
import Spinner from "../../../Spinner";

interface CustomsTabsProps {
    shipment: Shipment | null
}

const CustomsTab = ({shipment}: CustomsTabsProps) => {
    const [customs, setCustoms] = useState<ShipmentCustomeo[]>([]);
    const {index, isLoading} = useDatasource('shipment_customeos', ShipmentCustomeoNormalizer);
    const {isLoading: isLoadingAdd, lightGet} = useDatasource('shipment_customeos', ShipmentCustomeoNormalizer);

    const addNewCustoms = async () => {
        try {
            const res = await lightGet(`/api/shipment_customeos/instanciate_from_shipment/${shipment?.id}`);

            // on garde la ref Derudder dans le cas du premier MyCustoms
            if (customs.length !== 0) {
                res.derudderReference = (shipment?.derudderReference || '').concat('.', String(customs.length + 1));
            }
            res.shipment = shipment?.id ? `api/shipments/${shipment.id}` : null;

            setCustoms(prevState => {
                return [...prevState, res];
            });
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.log(err);
            }
        }

        return customs.length;
    };

    const replaceCustomAtIndex = (custom: ShipmentCustomeo, index: number) => {
        setCustoms((prevState) => {
            prevState.splice(index, 1, custom);

            return [...prevState];
        });
    }

    useEffect(() => {
        const controller = new AbortController();

        (async function () {
            if (!shipment?.id) {
                return;
            }

            try {
                const customeoList = await index({'shipment.id': shipment.id}, {}, controller);

                setCustoms(customeoList);
            } catch (err) {
                console.log(err);
            }
        })();

        return () => {
            controller.abort();
        }
    }, [index, shipment]);

    if (isLoading) {
        return <><Spinner/></>
    }

    return (
        <>
            <CreatableTabs mountOnEnter={true} onAdd={addNewCustoms} isAdding={isLoadingAdd}>
                {
                    customs.map((custom, index) => (
                        <Tab eventKey={index} title={`Ordre de douane n° ${index + 1}`} key={index}>
                            <Customeo customeo={custom} disabled={Boolean(custom.id)} index={index} onCreateOk={replaceCustomAtIndex}/>
                        </Tab>
                    ))
                }
            </CreatableTabs>
        </>
    );
};

export default CustomsTab;