import React, {useEffect, useState} from "react";
import {renderFormProps} from "../../../../FormController";
import {Col, Row} from "react-bootstrap";
import {Ticket} from "../../../../../model/Ticket";
import {ControlImportActions, DossierImportActions} from "../../../../../components/app/App/DossierImportActions";
import {ControlSwitchInput,} from '../../../../../components/SwitchButton';
import ConditionalInput from "../../../../fields/ConditionalInput/ConditionalInput";
import NumberInput from "../../../../fields/NumberInput";
import SelectInput, {SelectInputOptions} from "../../../../fields/SelectInput";
import {ShipmentEntrustWithConstants} from "../../../../../constants/model";
import {Shipment} from "../../../../../model/Shipment";
import {CustomerType} from "../../../../../model/Customer";
import useDatasource from "../../../../../hooks/useDatasource";
import {CustomerNormalizer} from "../../../../../services/normalizer/customerNormalizer";
import axios from "axios";

interface CustomerProps extends renderFormProps<Ticket> {
    shipment: Shipment,
}

export const Customer = ({register, resource, control, shipment}: CustomerProps) => {

    const [inactiveShipmentInsurance, setInactiveShipmentInsurance] = React.useState<boolean>(!shipment.insurance);
    const [customer, setCustomer] = useState<CustomerType | null>(null);
    const {get} = useDatasource<CustomerType>('clients', CustomerNormalizer);

    const id = typeof shipment.client === 'string' ? shipment.client.split('/').at(-1) : shipment.client.id;

    useEffect(() => {
        if (!id) {
            return;
        }

        const controller = new AbortController();

        // todo refactor avec lightGet
        get(
            typeof id === 'string' ? parseInt(id) : id, controller
        ).then(
            setCustomer
        ).catch(err => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        }
    }, [id, get]);

    const getworkflowEntrustUsWithOptions = (source: string[] | typeof ShipmentEntrustWithConstants) => {
        const options: SelectInputOptions[] = [];
        if(source) {
            Object.entries(source).forEach(([,value]) => {
                switch(value) {
                    case ShipmentEntrustWithConstants.PRE_DELIVERY:
                        options.push({label: 'Pré-acheminement', value: ShipmentEntrustWithConstants.PRE_DELIVERY})
                        break;
                    case ShipmentEntrustWithConstants.FREIGHT:
                        options.push({label: 'Fret - Transport', value: ShipmentEntrustWithConstants.FREIGHT})
                        break;
                    case ShipmentEntrustWithConstants.CUSTOMS:
                        options.push({label: 'Douane', value: ShipmentEntrustWithConstants.CUSTOMS})
                        break;
                    case ShipmentEntrustWithConstants.POST_DELIVERY:
                        options.push({label: 'Post-acheminement', value: ShipmentEntrustWithConstants.POST_DELIVERY})
                        break;
                    case ShipmentEntrustWithConstants.ARRIVAL_CUSTOMS:
                        options.push({label: 'Douane à l\'arrivée', value: ShipmentEntrustWithConstants.ARRIVAL_CUSTOMS})
                        break;
                }
            })
        }
        return options;
    }
    const actions: ControlImportActions[] = [
        {
            label: "Accusé de réception de la commande\u00A0?",
            name: "receiptAcknowledgment",
            value: resource.receiptAcknowledgment
        },
        {
            label: "Cotation\u00A0?", name: "quotation", value: resource.quotation, switchOffChildren: [
                {
                    label: "Demande service commercial\u00A0?",
                    name: "commercialRequest",
                    value: resource.commercialRequest
                }
            ], switchOnChildren: [
                {label: "Préfacturé\u00A0?", name: "preBilled", value: resource.preBilled}
            ]
        },
        {
            label: "Souscription assurance\u00A0?",
            name: "insuranceSubscription",
            value: resource.insuranceSubscription,
            inactive: inactiveShipmentInsurance
        },
        {label: "Demande déblocage\u00A0?", name: "releaseRequest", value: resource.releaseRequest},
    ];

    return <Row className={"border-bottom ms-0 me-0 pb-5"}>
        <Col lg={8}>
            <Row className={'mb-1'}>
                <h2 className={'col-lg-4'}>Client</h2>
                <Col lg={8}>
                    <Row>
                        <Col>
                            <p className={"label"}>
                                Gestionnaire
                            </p>
                            <p>
                                {shipment.userDerudder?.user?.fullname}
                            </p>
                        </Col>
                        <Col lg={6}>
                            <ControlSwitchInput control={control}
                                                label={'Me définir comme Backup ?'}
                                                labelClassName={'mt-0'}
                                                name={'userBackup'}
                                                value={resource.userBackup}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col lg={4}>
                    <p className={"label"}>
                        Client
                    </p>
                    <p className={'text-primary'}>
                        {customer?.name}
                    </p>
                </Col>
                <Col lg={4}>
                    <p className={"label"}>
                        Référence client
                    </p>
                    <p style={{whiteSpace: 'pre'}}>
                        {shipment.reference ?? '-'}
                    </p>
                </Col>
                <Col lg={4}>
                    <p className={"label"}>
                        Fournisseurs
                    </p>
                    <p>
                        {
                            shipment.displayedSuppliers && shipment.displayedSuppliers.length > 0
                                ? shipment.displayedSuppliers.join(', ')
                                : '-'
                        }
                    </p>
                </Col>

                <Col lg={4}>
                    <ControlSwitchInput control={control}
                                        label={'Client sur provision ?'}
                                        name={'shipment.customerOnProvision'}
                                        value={shipment.customerOnProvision}/>
                </Col>
                <ConditionalInput
                    control={control}
                    fieldName={'shipment.customerOnProvision'}
                    renderField={
                        () => <Col lg={4}>
                            <NumberInput
                                label={'Montant Proforma'}
                                name={'proformaAmount'}
                                control={control}
                            />
                        </Col>
                    }
                    fieldValue={true}
                    defaultValue={shipment.customerOnProvision}
                />
            </Row>
            <Row>

                <Col lg={4}>
                    <ControlSwitchInput control={control}
                                        label={'Assurance ?'}
                                        name={'shipment.insurance'}
                                        value={shipment.insurance}
                                        changeHandler={() => inactiveShipmentInsurance ? setInactiveShipmentInsurance(false) : setInactiveShipmentInsurance(true)}
                    />
                </Col>
                <ConditionalInput
                    control={control}
                    fieldName={'shipment.insurance'}
                    renderField={
                        () => <Col lg={4}>
                            <NumberInput
                                label={'Valeur de l’assurance'}
                                name={'insuranceAmount'}
                                control={control}
                                defaultValue={resource.insuranceAmount}
                            />
                        </Col>
                    }
                    fieldValue={true}
                    defaultValue={shipment.insurance}
                />
            </Row>
            <Row>
                <Col lg={4}>
                    <SelectInput
                        label={'Prestations (SLA) *'}
                        name={'shipment.workflowEntrustUsWith'}
                        control={control}
                        isMulti
                        selectOptions={getworkflowEntrustUsWithOptions(ShipmentEntrustWithConstants)}
                    />
                </Col>
                <Col lg={4}>
                    <p className={"label"}>
                        Incoterms
                    </p>
                    <p>
                        {shipment.incoterms?.name}
                    </p>
                </Col>
            </Row>
        </Col>

        <Col lg={4}>
            <h2>Actions</h2>
            {
                DossierImportActions(actions, register, control)
            }
        </Col>

    </Row>
}
