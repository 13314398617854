import {routesPosition, RoutesProps} from "../router.config";
import Kanban, {KanbanColumn} from "../../components/Kanban/Kanban";
import ShipmentKanbanCard from "../../components/app/App/ShipmentKanbanCard";
import {ColumnImportConstants} from "../../constants/model";
import ImportType from "../../forms/app/types/ImportType";
import {Profiles} from "../../model/Group";
import {TicketNormalizer} from "../../services/normalizer/ticketNormalizer";
import FilterController from "../../components/FilterController";
import ImportFilter from "../../forms/app/filters/ImportFilter";
import {Ticket} from "../../model/Ticket";

const columns: KanbanColumn<Ticket>[] = [];

interface OrderByDateOptions {
    resetTime: boolean
}

const columnOrderByDate = (
    aDate: [string, Date | null],
    bDate: [string, Date | null],
    options?: OrderByDateOptions
): number => {
    const [, aLoadingDate] = aDate;
    const [, bLoadingDate] = bDate;

    if (options && options.resetTime) {
        aLoadingDate?.setHours(0, 0, 0, 0);
        bLoadingDate?.setHours(0, 0, 0, 0);
    }

    if (aLoadingDate === null) {
        return 1;
    }
    if (bLoadingDate === null) {
        return -1;
    }

    // le +(Date) permet de cast en number la date (Timestamp) pour faire la comparaison
    return (+aLoadingDate - +bLoadingDate);
}

Object.entries(ColumnImportConstants).forEach(([, value]) => {
    switch (value) {
        case ColumnImportConstants.NEW_CASE:
            columns.push({label: 'Nouveau Dr', col: value})
            break;
        case ColumnImportConstants.LOADING:
            columns.push(
                {
                    label: 'FOB',
                    col: value,
                    orderBy: {departureDate: 'ASC'},
                    localOrderBy: (a, b) => {
                        return columnOrderByDate(
                            a.shipment.displayedLoadingDate,
                            b.shipment.displayedLoadingDate
                        );
                    },
                }
            )
            break;
        case ColumnImportConstants.ARRIVAL:
            columns.push(
                {
                    label: 'Arrivée',
                    col: value,
                    orderBy: {arrivalDate: 'ASC'},
                    localOrderBy: (a, b) => {
                        return columnOrderByDate(
                            a.shipment.ATA ? a.shipment.displayedUnloadingDate : a.shipment.orderedUnloadingDate,
                            b.shipment.ATA ? b.shipment.displayedUnloadingDate : b.shipment.orderedUnloadingDate,
                            {resetTime: true}
                        );

                    }
                }
            )
            break;
        default:
            columns.push({label: value, col: value})
    }
})

export const importRoutes: RoutesProps = {
    icon: 'fas fa-plane-arrival',
    name: 'Import',
    path: '/import/',
    position: routesPosition.leftSideBar,
    handle: {
        crumb: 'Import',
    },
    accessControl: [Profiles.ROLE_USER, Profiles.ROLE_ADMIN, Profiles.ROLE_SUPER_ADMIN],
    children: [
        {
            path: '/import/',
            element: <FilterController
                renderFilterable={(props) => <Kanban
                    {...props}
                    renderCard={(ticket) => <ShipmentKanbanCard ticket={ticket}/>}
                    columns={columns}
                    colSize={2}
                    namespace={'workflow_tickets'}
                    normalizer={TicketNormalizer}
                />}
                filterDefault={{category: 'import', 'shipment+archived': false, managedByMe: true}}
                renderFilter={(props) => <ImportFilter {...props} />}
            />,
            position: routesPosition.hiddenRoutes
        },
        {
            path: '/import/:id',
            element: <ImportType/>,
            handle: {
                crumb: 'Dossier',
            },
            position: routesPosition.hiddenRoutes,
        }
    ]
};
