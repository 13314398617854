import {routesPosition, RoutesProps} from "../router.config";
import {Profiles} from "../../model/Group";
import UserProfileType from "../../forms/app/types/UserProfileType";

export const userProfileRoute: RoutesProps = {
    path: '/profil',
    name: 'Mon profil',
    position: routesPosition.hiddenRoutes,
    accessControl: [Profiles.ROLE_USER, Profiles.ROLE_ADMIN, Profiles.ROLE_SUPER_ADMIN],
    element: <UserProfileType/>
}