import {Identifiable} from "./interface/Identifiable";
import {ShipmentPlatform} from "./ShipmentPlatform";
import {Customer} from "./Customer";
import {ShipmentTypeConstants, StatusConstants, TransportationModeConstants} from "../constants/model";
import {ShipmentIncoterm} from "./ShipmentIncoterm";
import {ShipmentCargaison} from "./ShipmentCargaison";
import {ShipmentCustomeo} from "./ShipmentCustomeo";
import {Invoice} from "./Invoice";
import {Comment} from './Comment';
import {ShipmentCompany} from "./ShipmentCompany";
import {User} from "./User";

export class Shipment implements Identifiable {

    cancelled: boolean = false;
    reference: string | null = null;
    derudderReference: string | null = null;

    traficCode: string;
    closed: boolean = false;
    comments: Comment[] = [];
    loadingPlatform: ShipmentPlatform | null = null;
    loadingEstimatedDate: Date | null;
    originPlatform: ShipmentPlatform | null;
    unloadingPlatform: ShipmentPlatform | null;
    destinationPlatform: ShipmentPlatform | null;
    events: Array<string> = [];
    status: StatusConstants = StatusConstants.CLOSED;
    bookingNumber: string | null = null;
    userDerudder: User | null = null;
    type: ShipmentTypeConstants = ShipmentTypeConstants.IMPORT;
    transportationMode: TransportationModeConstants | null = null;
    workflowEntrustUsWith: Array<string> = [];
    invoices: Array<Invoice> = [];
    client: Customer | string;
    displayedSuppliers: string[] = [];
    incoterms: ShipmentIncoterm;
    etdRequested: Date | null = null;
    etaRequested: Date | null = null;
    requestedDate: Date | null = null;
    deliveryDeadline: Date | null = null;
    imposedCompany: ShipmentCompany;
    insurance: boolean = false;
    transport: boolean = false;
    containerizationType: string | null = null;
    cargoes: ShipmentCargaison[] = [];
    blLtaCmr: string | null = null;
    copiesReceivedOn: Date | null = null;
    originalsReceivedOn: Date | null = null;
    originEstimatedDate: Date | null = null;
    originRealDate: Date | null = null;
    destinationEstimatedDate: Date | null = null;
    destinationRealDate: Date | null = null;
    loadingRealDate: Date | null;
    unloadingRealDate: Date | null = null;
    unloadingEstimatedDate: Date | null = null;
    unloadingInitialDate: Date | null = null;
    loadingShip: string | null = null;
    customer: Customer | null = null;
    transportReference: string | null = null; // TODO Revoir en fonction la gestion des différents transport
    customerOnProvision: boolean = false;
    archived: boolean;
    dispute: boolean = false;
    filingNumber: string | null = null;
    destuffingPlannedDate: Date | null;
    destuffingEndDate: Date | null;
    destuffing: boolean = false;
    delivery: boolean = true;
    warehouse: string | null = null;
    destuffingWarehouse: string | null = null;
    transportFeNumber: string | null = null;
    destuffingFeNumber: string | null = null;
    transportIcvNumber: string | null = null;
    destuffingIcvNumber: string | null = null;
    tractionOperator: string | null = null;
    flightNumber: string | null = null;
    trainNumber: string | null = null;
    travelNumber: string | null = null;
    tractionEstimatedDate: Date | null = null;
    tractionRealDate: Date | null = null;
    basiDate: Date | null = null;
    vaqDate: Date | null = null;
    latestDepartedShip: string | null = null;
    unloadingPredictiveDate: Date | null = null;
    unloadingCompanyPlannedDate: Date | null = null;

    constructor(
        public id: number | null,
        derudderReference: string | null,
        bookingNumber: string | null,
        originPlatform: ShipmentPlatform | null,
        destinationPlatform: ShipmentPlatform | null,
        etdRequested: Date | null,
        etaRequested: Date | null,
        transportationMode: TransportationModeConstants | null,
        trainNumber: string | null,
        loadingShip: string | null,
        blLtaCmr: string | null,
        flightNumber: string | null,
        client: Customer | string,
        containerizationType: string | null,
        deliveryDeadline: Date | null,
        unloadingPlatform: ShipmentPlatform | null,
        loadingPlatform: ShipmentPlatform | null,
        public customeos: ShipmentCustomeo[] | string[] = [],
        imposedCompany: ShipmentCompany,
        invoices: Invoice[],
        comments: Comment[],
        incoterms: ShipmentIncoterm,
        cargoes: ShipmentCargaison[],
        traficCode: string,
        transportReference: string,
        workflowEntrustUsWith: string[],
        cancelled: boolean,
        reference: string | null,
        customerOnProvision: boolean,
        archived: boolean,
        dispute: boolean,
        destuffingPlannedDate: Date | null,
        destuffingEndDate: Date | null,
        loadingEstimatedDate: Date | null,
        loadingRealDate: Date | null,
        warehouse: string | null,
        tractionOperator: string | null,
        insurance: boolean,
        transportIcvNumber: string | null,
        destuffingIcvNumber: string | null,
        travelNumber: string | null,
        filingNumber: string | null,
        userDerudder: User | null,
        originEstimatedDate: Date | null,
        originRealDate: Date | null,
        destinationEstimatedDate: Date | null,
        destinationRealDate: Date | null,
        unloadingDate: Date | null,
        unloadingEstimatedDate: Date | null,
        unloadingInitialDate: Date | null,
    ) {
        this.id = id;
        this.derudderReference = derudderReference;
        this.bookingNumber = bookingNumber;
        this.originPlatform = originPlatform;
        this.destinationPlatform = destinationPlatform;
        this.etdRequested = etdRequested;
        this.etaRequested = etaRequested;
        this.transportationMode = transportationMode;
        this.trainNumber = trainNumber;
        this.loadingShip = loadingShip;
        this.blLtaCmr = blLtaCmr;
        this.flightNumber = flightNumber;
        this.client = client;
        this.containerizationType = containerizationType;
        this.deliveryDeadline = deliveryDeadline;
        this.unloadingPlatform = unloadingPlatform;
        this.loadingPlatform = loadingPlatform;
        this.customeos = customeos;
        this.imposedCompany = imposedCompany;
        this.invoices = invoices;
        this.comments = comments;
        this.incoterms = incoterms;
        this.cargoes = cargoes;
        this.traficCode = traficCode;
        this.transportReference = transportReference;
        this.workflowEntrustUsWith = workflowEntrustUsWith ?? [];
        this.cancelled = cancelled;
        this.reference = reference;
        this.customerOnProvision = customerOnProvision;
        this.archived = archived;
        this.dispute = dispute;
        this.destuffingPlannedDate = destuffingPlannedDate;
        this.destuffingEndDate = destuffingEndDate;
        this.loadingEstimatedDate = loadingEstimatedDate;
        this.loadingRealDate = loadingRealDate;
        this.warehouse = warehouse;
        this.tractionOperator = tractionOperator;
        this.insurance = insurance;
        this.transportIcvNumber = transportIcvNumber;
        this.destuffingIcvNumber = destuffingIcvNumber;
        this.travelNumber = travelNumber;
        this.filingNumber = filingNumber;
        this.userDerudder = userDerudder;
        this.originEstimatedDate = originEstimatedDate;
        this.originRealDate = originRealDate;
        this.destinationEstimatedDate = destinationEstimatedDate;
        this.destinationRealDate = destinationRealDate;
        this.unloadingRealDate = unloadingDate;
        this.unloadingEstimatedDate = unloadingEstimatedDate;
        this.unloadingInitialDate = unloadingInitialDate;
    }

    get trafficIdentification() {
        switch (this.transportationMode) {
            case TransportationModeConstants.ROAD:
                return this.blLtaCmr;
            case TransportationModeConstants.SEA:
                /**
                 * renvoi le navire courant ou le celui de chargement si pas d'info
                 */
                return this.latestDepartedShip ? this.latestDepartedShip : this.loadingShip;
            case TransportationModeConstants.AIR:
                return this.flightNumber;
            case TransportationModeConstants.TRAIN:
                return this.trainNumber;
            default:
                return '';
        }
    }

    get ETD () {
        return this.loadingEstimatedDate;
    }

    get ETA () {
        return this.unloadingInitialDate;
    }

    get ATD () {
        return this.loadingRealDate;
    }

    get ATA () {
        return this.unloadingRealDate;
    }

    get PRD () {
        return this.unloadingPredictiveDate;
    }

    get displayedLoadingDate(): [('ATD' | 'ETD'), Date | null] {
        if (this.ATD) {
            return ['ATD', this.ATD]
        }
        return ['ETD', this.ETD]
    }

    get displayedUnloadingDate(): [('ATA' | 'ETA'), Date | null] {
        if (this.ATA) {
            return ['ATA', this.ATA]
        }
        return ['ETA', this.ETA]
    }

    get displayedUnloadingPredictiveDate(): ['PRD', Date | null] {
        return ['PRD', this.PRD]
    }

    get orderedUnloadingDate(): [('ETA' | 'PRD'), Date | null] {
        if (this.PRD) {
            return ['PRD', this.PRD]
        }
        return ['ETA', this.ETA]
    }
}

export type ShipmentType = Shipment;

