import {RouteProps} from "react-router-dom";
import * as React from "react";
import {Profiles} from "../model/Group";

export enum routesPosition {
    leftSideBar,
    RightSideBar,
    hiddenRoutes
}
export interface RoutesProps {
    path?: RouteProps['path'];
    position: routesPosition;
    name?: string;
    element?: RouteProps['element'];
    caseSensitive?: RouteProps['caseSensitive'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
    index?: RouteProps['index'];
    handle?: {
        crumb: string
    }
    errorElement?: React.ReactNode | null;
    accessControl?: Array<Profiles>
}

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const filterRoutes = (routes: RoutesProps[]) => {
    const filteredRoutes: RoutesProps[] = [];

    routes.filter((route) => route.path !== undefined)
        .filter((route) => route.name !== undefined)
        .map((route) => {
            return filteredRoutes.push(route);
        });
    return filteredRoutes;
}

const filterProtectedRoutes = (routes: RoutesProps[], userRoles: Profiles[]) => {
    const filteredAllowedRoutes: RoutesProps[] = [];
    routes.map((route) => {
        return route.accessControl ? route.accessControl.filter(role => userRoles.includes(role)).map(() => {
                return filteredAllowedRoutes.push(route);
            })
            : filteredAllowedRoutes.push(route);
    })
    return filteredAllowedRoutes;
}

export {flattenRoutes, filterRoutes, filterProtectedRoutes};