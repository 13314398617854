import {normalizedType, Normalizer} from "./normalizer";
import {Invoice} from "../../model/Invoice";

const InvoiceNormalizer = new class implements Normalizer<Invoice>{
    normalize(data: normalizedType<Invoice>): Invoice {

        return new Invoice(
            data.id,
            data.name,
            data.invoiceDate ? new Date(data.invoiceDate) : null
        );
    }
}();

export { InvoiceNormalizer };
