import {Customer} from "./Customer";
import {Carrier} from "./Carrier";
import {DeliveryStatusConstants, TransportationModeConstants, TransportationTypeConstants} from "../constants/model";

export class Commande {
    apiId: number = 0
    customerId: number = 0
    client: Customer | null = null
    transportType: TransportationTypeConstants | '' = ''
    transportationMode: TransportationModeConstants | '' = ''
    type: string = ''
    refClient: string = ''
    time: Date = new Date()
    statusCode: number = 0
    timestring: string = ''
    ref: string = ''
    tcSize: string = ''
    tcType: string = ''
    tcNumber: string = ''
    tcSeal: string = ''
    goodsNb: number = 0
    goodsWeight: number = 0
    goodsName: string = ''
    goodsDangerous: boolean = false
    goodsDangerousName: string = ''
    positAddressName: string = ''
    positAddressStreet: string = ''
    positAddressPostcode: string = ''
    positAddressCity: string = ''
    positAddressCountry: string = ''
    transportDate: Date = new Date()
    transportDestination: string = ''
    contactName: string = ''
    contactEmail: string = ''
    contactNumber: string = ''
    anomaly: boolean = false
    numeroPulse: string = ''
    deliveredAt: Date | null = null
    comment: string | null = null
    carrier: Carrier | '' | null = null;

    constructor(
        public id: number = 0,
        public date: Date | null = null,
        public calculatedTime: Date | null = null,
        public statusWorkflow: DeliveryStatusConstants | string = '',
        public canceled: boolean = false,
        public transportBoat: string = '',
        public fromLocation: string = '',
        public toLocation: string = '',
    ) {
    }
}

export type CommandeType = Commande;