import {useUncontrolled} from 'uncontrollable';
import {Card, Nav, Tab, TabsProps as BaseTabsProps} from "react-bootstrap";
import * as React from "react";
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import {forEach, map} from "react-bootstrap/ElementChildren";

// @see https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Tabs.tsx

export interface TabsProps extends BaseTabsProps {

}

export function getDefaultActiveKey(children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactNode | ReactPortal | null | undefined) {
    let defaultActiveKey: any;
    forEach(children, (child) => {
        if (defaultActiveKey == null) {
            defaultActiveKey = child.props.eventKey;
        }
    });

    return defaultActiveKey;
}

function renderTab(child: { props: { title: any; eventKey: any; disabled: any; tabClassName: any; tabAttrs: any; id: any; }; }) {
    const { title, eventKey, disabled, tabClassName, tabAttrs, id } = child.props;
    if (title == null) {
        return null;
    }

    return (
        <Nav.Item as="li" role="presentation">
            <Nav.Link
                as="button"
                type="button"
                eventKey={eventKey}
                disabled={disabled}
                id={id}
                className={tabClassName}
                // évite que l'onglet cliqué ait le style actif notamment dans le cas ou le parent s'approprie le contrôle des onglets actif
                // @see CreatableTabs.tsx
                onFocus={e => {e.target.blur()}}
                {...tabAttrs}
            >
                {title}
            </Nav.Link>
        </Nav.Item>
    );
}

export const Tabs = (props: TabsProps) => {

    const {
        id,
        onSelect,
        transition,
        mountOnEnter = false,
        unmountOnExit = false,
        variant = 'tabs',
        children,
        activeKey = getDefaultActiveKey(children),
        ...controlledProps
    } = useUncontrolled(props, {
        activeKey: 'onSelect',
    });

    return (
        <Card className={"bg-white h-100 mb-0 overflow-hidden"}>
            <Card.Body className={'d-flex flex-column flex-fill overflow-hidden'}>
                <Tab.Container
                    id={id}
                    activeKey={activeKey}
                    onSelect={onSelect}
                    transition={transition}
                    mountOnEnter={mountOnEnter}
                    unmountOnExit={unmountOnExit}
                >
                    <Nav className="nav-bordered" {...controlledProps} as="ul" variant={variant} role={"tablist"}>
                        {map(children, renderTab)}
                    </Nav>
                    <Tab.Content
                        className={'d-flex flex-column flex-fill overflow-auto'}
                    >
                        {map(children,(child) => {
                            const childProps = { ...child.props };

                            delete childProps.title;
                            delete childProps.disabled;
                            delete childProps.tabClassName;
                            delete childProps.tabAttrs;

                            return (
                                <Tab.Pane {...childProps}/>
                            );
                        })}
                    </Tab.Content>
                </Tab.Container>
            </Card.Body>
        </Card>
    )
}
