import {Identifiable} from "../model/interface/Identifiable";
import {useCallback, useContext, useMemo, useState} from "react";
import {TokenContext} from "../context/TokenContext";
import axios, {isCancel} from "axios";
import {Normalizer} from "../services/normalizer/normalizer";
import {Path} from "react-hook-form";

export type FilterType = {
    [key: string]: any
}

export type OrderType = {
    [key: string]: 'ASC' | 'DESC' | null
}

export interface ApiViolation<ResourceT> {
    code: string
    message: string
    propertyPath: Path<ResourceT>
}

export interface ApiError<ResourceT> {
    detail: string
    title: string
    type: string
    violations: ApiViolation<ResourceT>[]
}

interface ApiHydraResponse<ResourceT> {
    '@context': string
    '@id': string
    '@type': string
    'hydra:member': ResourceT[]
    'hydra:totalItems': number,
    'hydra:view': {
        'hydra:first': string
        'hydra:last': string
        'hydra:next'?: string
        'hydra:previous'?: string
    }
}

type DatasourceResponse<T> = ApiHydraResponse<T> | T | T[]

export const isApiError = <T extends Identifiable>(error: any): error is ApiError<T> => {
    if (typeof error !== 'object' || !error) {
        return false;
    }

    return (
        'detail' in error
        && 'title' in error
        && 'type' in error
        && 'violations' in error
    );
}

export const isHydra = <T extends Identifiable>(response: DatasourceResponse<T>): response is ApiHydraResponse<T> => {
    return 'hydra:member' in response
}

export interface Datasource<T extends Identifiable> {
    index(filters?: FilterType, orders?: OrderType, controller?: AbortController, isPaginated?: boolean): Promise<Array<T>>
    update(resource: Partial<T> & Identifiable, context: any): Promise<T>
    create(resource: T, context: any): Promise<T>
    delete?(resource: T): Promise<boolean>
    get(id: T["id"], controller?: AbortController): Promise<T>
    lightIndex(iri: string, filters?: FilterType, orders?: OrderType, controller?: AbortController, isPaginated?: boolean): Promise<T[]>
    lightGet(iri: string, controller?: AbortController): Promise<T>
}

const transform = (data: any) =>
    (acc: object, key: string) =>
        ({...acc, ...{[key.replaceAll('+', '.')]: data[key]}})

export default function useDatasource<ResourceT extends Identifiable>(namespace: string, normalizer: Normalizer<ResourceT>): Datasource<ResourceT> & {
    isLoading: boolean;
    lastResponse: ApiHydraResponse<ResourceT> | ResourceT[] | ResourceT | null;
    resetLastResponse: () => void
} {

    const {token} = useContext(TokenContext);

    const [isLoading, setIsLoading] = useState(false);
    const [lastResponse, setLastResponse] = useState<DatasourceResponse<ResourceT> | null>(null);

    const resetLastResponse = useCallback(() => {
        setLastResponse(null);
    }, []);

    const datasourceFunction = useMemo((): Datasource<ResourceT> => {

        const url = process.env.REACT_APP_API_URL;

        const headers = {
            Accept: 'application/json',
            Authorization: 'Token ' + token,
        };

        const ldHeaders = {
            Accept: 'application/ld+json',
            Authorization: 'Token ' + token,
        };

        const patchHeader = {
            Accept: 'application/json',
            Authorization: 'Token ' + token,
            "Content-Type": 'application/merge-patch+json'
        };

        const debug =/* process.env.NODE_ENV === 'development' ? 'app_dev.php/' : */'';

        return ({
            index(filters?: FilterType, order?: OrderType, controller?: AbortController, isPaginated: boolean = false): Promise<Array<ResourceT>> {

                const transformedFilters = filters ? Object.keys(filters).reduce(transform(filters), {}) : {};

                setIsLoading(true);

                return axios.get(`${url}/${debug}api/${namespace}`, {
                    headers: headers,
                    params: {...transformedFilters, order: {...order}, pagination: isPaginated},
                    signal: controller ? controller.signal : undefined,
                }).then((res) => {
                    setIsLoading(false);
                    return normalizer.normalize ? res.data.map(normalizer.normalize) : res.data
                });
            },
            async lightIndex(iri: string, filters, order, controller?: AbortController, isPaginated: boolean = false): Promise<ResourceT[]> {
                setIsLoading(true);
                const transformedFilters = filters ? Object.keys(filters).reduce(transform(filters), {}) : {};
                try {
                    const response =  await axios.get<ApiHydraResponse<ResourceT>>(
                        `${url}${iri[0] === '/' ? iri : '/' + iri}`,
                        {
                            headers: ldHeaders,
                            params: {...transformedFilters, order: {...order}, pagination: isPaginated},
                            signal: controller ? controller.signal : undefined,
                        }
                    );

                    setIsLoading(false);
                    setLastResponse(response.data);

                    return normalizer.normalize
                        ? response.data['hydra:member'].map(normalizer.normalize)
                        : response.data['hydra:member']
                } catch (err) {
                    // évite un conflict avec le isLoading dans le cas des coupures
                    if (!isCancel(err)) {
                        setIsLoading(false);
                    }
                    throw err;
                }
            },
            get(id: ResourceT["id"], controller?: AbortController): Promise<ResourceT> {
                setIsLoading(true);
                return axios.get(`${url}/${debug}api/${namespace}/${id}`, {
                    headers: headers,
                    signal: controller ? controller.signal : undefined,
                }).then((res) => {
                    setIsLoading(false);
                    return normalizer.normalize ? normalizer.normalize(res.data) : res.data
                }).catch((err) => {
                    if (!axios.isCancel(err)) {
                        setIsLoading(false);
                    }
                    throw err;
                });
            },
            async lightGet(iri: string, controller?: AbortController): Promise<ResourceT> {
                setIsLoading(true);
                try {
                    const response =  await axios.get(`${url}${iri}`, {
                        headers: headers,
                        signal: controller ? controller.signal : undefined,
                    });

                    setIsLoading(false);

                    return normalizer.normalize ? normalizer.normalize(response.data) : response.data
                } catch (err) {
                    setIsLoading(false);
                    throw err;
                }
            },
            async update(resource, context) {
                setIsLoading(true);

                try {
                    const response = await axios.patch(
                        `${url}/${debug}api/${namespace}/${resource.id}`,
                        normalizer.serialize ? normalizer.serialize(resource, context) : resource,
                        {
                            headers: patchHeader,
                        }
                    );
                    setIsLoading(false);
                    return normalizer.normalize ? normalizer.normalize(response.data) : response.data;
                } catch (err) {
                    setIsLoading(false);
                    throw err;
                }
            },
            async create(resource, context) {
                try {
                    const response = await axios.post<ResourceT>(`${url}/${debug}api/${namespace}`, normalizer.serialize ? normalizer.serialize(resource, context) : resource, {
                        headers: headers,
                    });

                    return normalizer.normalize ? normalizer.normalize(response.data) : response.data as ResourceT;
                } catch (err) {
                    console.log(err)
                    throw err;
                }
            },
        });
    }, [namespace, normalizer, token]);

    return {isLoading, lastResponse, resetLastResponse, ...datasourceFunction}
}
