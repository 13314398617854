import React, {useCallback, useContext, useEffect, useState} from "react";
import {WorkflowComment} from "../../../../model/WorkflowComment";
import useDatasource from "../../../../hooks/useDatasource";
import {WorkflowCommentNormalizer} from "../../../../services/normalizer/WorkflowCommentNormalizer";
import {FlashContext} from "../../../../context/FlashContext";
import {FormController} from "../../../../forms/FormController";
import {Form} from "react-bootstrap";
import ButtonComponent from "../../../ButtonComponent";
import classNames from "classnames";
import * as Chat from "../../../../forms/app/types/Import/Chat";
import {Ticket} from "../../../../model/Ticket";

interface CommentTabProps {
    ticket: Ticket | null
}

const CommentTab = ({ticket}: CommentTabProps) => {
    const [comments, setComments] = useState<WorkflowComment[]>([]);
    const {index, create} = useDatasource<WorkflowComment>('workflow_comments', WorkflowCommentNormalizer)
    const [isLoading, setIsLoading] = useState(true);

    const {addSuccess} = useContext(FlashContext);

    const addToBeginning = (data: WorkflowComment) => {
        setComments((prevState) => {
            prevState.unshift(data);
            return prevState;
        });
    }

    const fetch = useCallback((controller?: AbortController) => {
        index({'workflowTicket.id': ticket?.id}, {id: "DESC"}, controller)
            .then(setComments)
            .then(() => setIsLoading(false))
            .catch(console.log)
    }, [index, ticket]);

    const postComment = useCallback(
        async (data: WorkflowComment) => {
            setIsLoading(true)
            data.workflowTicket = 'api/workflow_tickets/' + ticket?.id;

            try {
                const newComment = await create(data, 'dossier')
                addSuccess('Commentaire ajouté avec succès.');

                if (newComment) {
                    addToBeginning(newComment)
                }
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        }
        , [create, addSuccess, ticket]
    )

    useEffect(() => {
        const controller = new AbortController()

        fetch(controller);

        return () => {
            controller.abort();
        }
    }, [fetch]);

    return (
        <>
            <FormController
                renderForm={(props) => <>
                    <Chat.ChatForm {...props}/>
                    <Form.Group className={"d-flex justify-content-end pe-2"}>
                        <ButtonComponent
                            buttonLabel={"Enregistrer"}
                            buttonType={"submit"}
                            classes={"px-4"}
                            buttonIcon={classNames(
                                isLoading ? "spinner-border text-white spinner-border-sm" : "fas fa-save"
                            )}
                        />
                    </Form.Group>
                </>}
                namespace={'workflow_comments'}
                normalizer={WorkflowCommentNormalizer}
                newResourceCreator={() => new WorkflowComment()}
                handleSubmitEvent={() => (data: WorkflowComment) => postComment(data)}
                resetOnSubmit={true}
            />
            <Chat.WorkflowComment
                resources={comments}
                isLoading={isLoading}
            />
        </>
    );
};

export default CommentTab;