import {normalizedType, Normalizer} from "./normalizer";
import {Commande} from "../../model/Commande";

const CommandeNormalizer = new class implements Normalizer<Commande>{
    normalize(data: normalizedType<Commande>): Commande {

        const commande = new Commande(
            data.id,
            data.date ? new Date(data.date) : null,
            data.calculatedTime ? new Date(data.calculatedTime) : null,
            data.statusWorkflow,
            data.canceled,
            data.transportBoat,
            data.fromLocation,
            data.toLocation,
        );

        commande.transportType = data.transportType;
        commande.carrier = data.carrier;

        return commande;
    }
}();

export { CommandeNormalizer };
