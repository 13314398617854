import {ControlSwitchInput} from '../../SwitchButton';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Control, UseFormRegister} from 'react-hook-form';
import ConditionalInput from '../../../forms/fields/ConditionalInput/ConditionalInput';
import DateInput from '../../../forms/fields/DateInput';
import TextInput from '../../../forms/fields/TextInput';

export interface ControlImportActions {
    label?: string,
    name: string,
    value?: any,
    switchOffChildren?: ControlImportActions[],
    switchOnChildren?: ControlImportActions[],
    noSwitchChildren?: ControlImportActions[],
    type?: 'bool' | 'date' | 'datetime-local' | 'text' | 'label',
    inactive?: boolean
}

const SwitchAction = ({
    action,
    control,
}: { action: ControlImportActions, control: Control }) => {

    if(action.inactive) {
        return null
    }

    return <>
        {
            action.label ? (
                <label className={'mb-0'} htmlFor={action.name}>
                    {action.label}
                </label>
            ) : null
        }

        <ControlSwitchInput
            control={control}
            name={action.name}
            value={action.value}
        />
    </>;
};

export const DossierImportActions = (
    actions: ControlImportActions[],
    register: UseFormRegister<any>,
    control: Control,
) => {

    const renderInput = (action: ControlImportActions) => {
        switch (action.type) {
            case 'date':
            case 'datetime-local':
                return (<DateInput
                    name={action.name}
                    label={action.label}
                    control={control}
                    type={action.type}
                />);
            case 'text':
                return (<TextInput name={action.name} label={action.label}
                                   placeholder={action.label} control={control}
                />);
            case 'label':
                return (<p className={'mt-1 fw-bold'}>{action.label}</p>);
            default:
                return (<SwitchAction action={action}
                                      control={control}/>);
        }
    };
    return actions.map((action, index: number) => (
        <Row key={index} className={'mb-2 actions-row'}>
            <Col>
                {
                    renderInput(action)
                }
            </Col>
            {action.switchOnChildren || action.switchOffChildren ?
                <Col>
                    {
                        action.switchOnChildren ? (
                            <ConditionalInput
                                control={control}
                                fieldName={action.name}
                                defaultValue={action.value}
                                fieldValue={true}
                                renderField={
                                    () => <>
                                        {DossierImportActions(action.switchOnChildren ||
                                            [], register, control)}
                                    </>
                                }/>
                        ) : null
                    }
                    {
                        action.switchOffChildren ? (
                            <ConditionalInput
                                control={control}
                                fieldName={action.name}
                                defaultValue={action.value}
                                fieldValue={false}
                                renderField={
                                    () => <>
                                        {DossierImportActions(action.switchOffChildren ||
                                            [], register, control)}
                                    </>
                                }/>
                        ) : null
                    }
                </Col>
            : null}
            {
                action.noSwitchChildren ?
                    DossierImportActions(
                        action.noSwitchChildren || [],
                        register,
                        control,
                    )
                    : null
            }
        </Row>
    ));
};