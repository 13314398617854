import {Form} from "react-bootstrap";
import React from "react";
import {Control, Controller} from "react-hook-form";

type RadioInputProps = {
    containerClass?: string
    label?: string,
    name: string,
    resourceValue?: any,
    fieldValue: string,
    control: Control,
};

export default function RadioInput({name, label, containerClass, control, fieldValue}: RadioInputProps) {

    const errors = {[name]: {message: ''}};
    return (
        <Form.Group className={containerClass}>

            <Controller
                control={control}
                name={name}
                render={
                    (
                        {
                            field: {onChange, name, value},
                        }
                    ) => {
                        return (
                            <Form.Check
                                type={'radio'}
                                label={label ?? ''}
                                name={name}
                                onChange={onChange}
                                value={fieldValue}
                                checked={fieldValue === value}
                            />
                        )
                    }
                }
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message?.toString()}
                </Form.Control.Feedback>
            ) : null}

        </Form.Group>
    )
}
