import {FileManager} from "../FileManager";
import {Col, Row} from "react-bootstrap";
import Spinner from "../../../Spinner";
import {useEffect, useState} from "react";
import useDatasource from "../../../../hooks/useDatasource";
import {ShipmentAttachedFile} from "../../../../model/ShipmentAttachedFile";
import {Shipment} from "../../../../model/Shipment";
import {ShipmentAttachedFileNormalizer} from "../../../../services/normalizer/ShipmentAttachedFileNormalizer";

interface FileTabProps {
    shipment: Shipment | null
}

const FileTab = ({shipment}: FileTabProps) => {

    const [files, setFiles] = useState<ShipmentAttachedFile[]>([]);

    const {index} = useDatasource<ShipmentAttachedFile>('shipment_attached_files', ShipmentAttachedFileNormalizer);

    const addFiles = (files: ShipmentAttachedFile[]) => {
        setFiles((prevFiles) => {
            console.log(files);
            return prevFiles.concat(files);
        });
    }

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            if (!shipment) {
                return;
            }
            const data = await index({shipment: shipment.id});

            setFiles(data);
        }

        fetchData().catch(console.error);

        return () => {
            controller.abort();
        }
    }, [index, shipment]);

    if (!shipment) {
        return (
            <Spinner/>
        );
    }

    return (
        <>
            <Row>
                <Col md={3}>
                    <FileManager
                        files={files}
                        type={'shipmentDocument'}
                        shipment={shipment}
                        onUploadSuccess={addFiles}
                        confirmUpload={false}
                    />
                </Col>
            </Row>
        </>
    );
};

export default FileTab