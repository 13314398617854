import {Invoice} from "./Invoice";

export class InvoicePdf {
    id: number = 0;
    file: string = '';
    invoiceNumber: string = '';
    caseNumber: string = '';
    fileName: string = '';
    invoiceDate: Date | null = null;
    name: string | null = null;

    constructor(
        public invoice: Invoice | null
    ) {
    }
}