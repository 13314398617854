import React, {MouseEventHandler} from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

interface BottomLinksProps {
    onclick: MouseEventHandler,
    links: Array<any>,
}

const BottomLinks = ({onclick, links}: BottomLinksProps) => {

    return (
        <>
            <Row className="mt-3">
                <Col className="text-center">
                    {
                        links.map((link, index) => {

                            return <p className="text-primary-50 ms-1" key={index}>
                                {link.helptext ?
                                    link.helptext + ' ' :
                                    null
                                }
                                <Link to={'#'}
                                      className={link.helptext ? "fw-bold" : ""}
                                      onClick={onclick}
                                      id={link.id}
                                >{link.label}</Link>
                            </p>
                        })
                    }
                </Col>
            </Row>
        </>
    )
}

export default BottomLinks;