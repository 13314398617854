import {FieldValues, useForm} from "react-hook-form";
import EmailInput from "../../fields/EmailInput";
import {Alert} from "react-bootstrap";
import React from "react";
import ButtonComponent from "../../../components/ButtonComponent";

interface PasswordFormProps {
    formClass?: string;
    id?: string,
    formName?: string
}

const ForgottenPasswordForm = ({formClass, id, formName}: PasswordFormProps) => {
    const [error] = React.useState()

    const methods = useForm({});
    const {
        handleSubmit,
        register,
    } = methods;

    const reinitializePassword = (FormData: FieldValues) => {
        console.log(FormData);
    };

    return (
        <>
            {error ? (
                <Alert variant="danger" className="my-2">
                    {error}
                </Alert>
            ) : null}

            <form id={id} name={formName} onSubmit={handleSubmit((data) => {reinitializePassword(data)})} className={formClass} noValidate>
                <EmailInput
                    name={"username"}
                    containerClass={'form-group mb-3'}
                    register={register}
                    placeholder={"Adresse email"}
                />

                <div className="d-grid text-center">
                    <ButtonComponent
                        buttonVariant={"primary"}
                        buttonLabel={"Envoyer"}
                        buttonType={"submit"}
                        classes={"btn waves-effect waves-light btn-lg btn-block mt-2"}
                    />
                </div>

            </form>
        </>
    );
}

export default ForgottenPasswordForm;