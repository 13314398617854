import * as React from 'react';
import {FileResource} from "../../../model/interface/FileResource";
import {Card, Col, Row} from "react-bootstrap";
import classNames from "classnames";
import {ProtectedFileDownload} from "./ProtectedFileDownload";
import FileUploader from "../../FileUpload/FileUploader";
import {Shipment} from "../../../model/Shipment";
import {ShipmentAttachedFile} from "../../../model/ShipmentAttachedFile";

export type FileManagerProps<ResourceT extends FileResource> = {
    title?: string
    files?: ResourceT[]
    type: 'shipmentInvoice' | 'shipmentPacking' | 'shipmentBlLta' | 'shipmentDocument'
    shipment: Shipment
    onUploadSuccess: (files: ShipmentAttachedFile[]) => void;
    confirmUpload?: boolean
};

export type FileIconProps = {
    mime?: FileResource['mime']
}

export function FileIcon({mime = null}: FileIconProps) {

    const iconClass = ['fas']

    switch (mime) {
        case 'text/plain':
            iconClass.push('fa-file-alt');
            break;
        case 'application/pdf':
            iconClass.push('fa-file-pdf');
            break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            iconClass.push('fa-file-excel');
            break;
        case null:
            iconClass.pop();
            iconClass.push('mdi mdi-file-question-outline');
            break;
        default:
            iconClass.push('fa-file');
            break;
    }

    return (
        <div className="avatar-sm">
            <span className="avatar-title bg-light text-secondary rounded">
                <i className={classNames(iconClass, !mime ? 'text-danger' : undefined)}></i>
            </span>
        </div>
    );
}

export function FileManager<ResourceT extends FileResource>({title, files = [], type, shipment, onUploadSuccess, confirmUpload}: FileManagerProps<ResourceT>) {

    return (
        <section>
            {
                title
                    ? (
                        <h2>
                            {title}
                        </h2>
                    ) : null
            }
            <Row>
                <Col sm={12} className="mb-2">
                    <FileUploader
                        type={type}
                        shipmentId={shipment.id}
                        onFileUpload={onUploadSuccess}
                        confirmUpload={confirmUpload}
                    />
                </Col>
                {(files).map((f, i) => {
                    return (
                        <Col key={i} lg={12}>
                            <Card className="shadow-none border mb-1">
                                <Row className="align-items-center">
                                    <Col className="col-auto pe-0">
                                        <FileIcon mime={f.mime}/>
                                    </Col>
                                    <Col>
                                        <p className={classNames("mb-0", !f.url ? 'text-danger' : undefined)}>
                                            <ProtectedFileDownload file={f}>
                                                <p className={classNames('text-muted fw-bold', !f.url ? 'text-danger' : undefined)}>
                                                    {f.name}
                                                </p>
                                            </ProtectedFileDownload>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </section>
    );
}