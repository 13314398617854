import {normalizedType, Normalizer, serializedType} from "./normalizer";
import {Ticket} from "../../model/Ticket";
import {ShipmentNormalizer} from "./shipmentNormalizer";
import {incotermIncludes} from "../../utils/model";

const TicketNormalizer = new class implements Normalizer<Ticket> {
    normalize(data: normalizedType<Ticket>): Ticket {

        const shipment = ShipmentNormalizer.normalize(data.shipment);

        const ticket = new Ticket(
            data.id,
            shipment,
            data.column,
            data.order,
            data.category,
            data.customsPreparationDate
        );

        ticket.bae = data.baeDate ? true : data.bae;
        ticket.baeDate = data.baeDate ? new Date(data.baeDate) : null;
        ticket.bas = data.basDate ? true : data.bas;
        ticket.basDate = data.basDate ? new Date(data.basDate) : null;
        ticket.quaysideView = data.quaysideView;
        ticket.quaysideViewDate = data.quaysideViewDate ? new Date(data.quaysideViewDate) : null;
        ticket.receiptAcknowledgment = data.receiptAcknowledgment;
        ticket.quotation = data.quotation;
        ticket.commercialRequest = data.commercialRequest;
        ticket.preBilled = data.preBilled;
        ticket.insuranceSubscription = data.insuranceSubscription;
        ticket.cifFob = incotermIncludes(['FOB', 'CIF'], shipment);
        ticket.releaseRequest = data.releaseRequest;
        ticket.bookingRequest = data.bookingRequest;
        ticket.customerBoardingForecast = data.customerBoardingForecast;
        ticket.customerBoardingConfirmation = data.customerBoardingConfirmation;
        ticket.copy = data.copyDate ? true : data.copy;
        ticket.copyDate = data.copyDate ? new Date(data.copyDate) : null;
        ticket.expressRelease = data.expressRelease;
        ticket.requestOriginalsToCustomer = data.requestOriginalsToCustomer;
        ticket.originals = data.originals;
        ticket.originalsDate = data.originalsDate ? new Date(data.originalsDate) : null;
        ticket.customsPreparation = data.dateTimeReady ? true : data.customsPreparation;
        ticket.customsPreparationDate = data.dateTimeReady ?? (data.customsPreparationDate ? new Date(data.customsPreparationDate) : null);
        ticket.dossCcsCreation = data.dossCcsCreation;
        ticket.ccsClearance = data.ccsClearance;
        ticket.originalsDocumentDepositedToCompanyAndPayment = data.originalsDocumentDepositedToCompanyAndPayment;
        ticket.badCompany = data.badCompany;
        ticket.badCompanyReceivedOn = data.badCompanyReceivedOn ? new Date(data.badCompanyReceivedOn) : null;
        ticket.badCompanyRequest = data.badCompanyReceivedOn ? true : data.badCompanyRequest;
        ticket.badCompanyDate = data.badCompanyReceivedOn ?? (data.badCompanyDate ? new Date(data.badCompanyDate) : null);
        ticket.customsTransfer = data.customsTransfer;
        ticket.aheadOfTime = data.aheadOfTime;
        ticket.triggerMyCustomsOkForClearance = data.triggerMyCustomsOkForClearance;
        ticket.requestCustomsInspection = data.requestCustomsInspection;
        ticket.sycoscan = data.sycoscan;
        ticket.idTPT = data.idTPT;
        ticket.crv = data.crv;
        ticket.tct = data.tct;
        ticket.control = data.control;

        ticket.requestUnloading = data.requestUnloading;
        ticket.requestTraction = data.requestTraction;
        ticket.unloadingCommand = data.unloadingCommand;
        ticket.terminalReturnRequest = data.terminalReturnRequest;
        ticket.customsVisit = data.customsVisit;
        ticket.customsVisitDate = data.customsVisitDate;
        ticket.customsGreenLights = data.customsGreenLights;
        ticket.wareControl = data.wareControl;
        ticket.returnAnalysis = data.returnAnalysis;
        ticket.returnAnalysisDate = data.returnAnalysisDate;
        ticket.unloadingDone = data.unloadingDone;
        ticket.unloadingReport = data.unloadingReport;
        ticket.unloadingReportReceivedDate = data.unloadingReportReceivedDate;
        ticket.loadingCommand = data.loadingCommand;
        ticket.carriersName = data.carriersName;
        ticket.paletteSizeAndType = data.paletteSizeAndType;
        ticket.reserved = data.reserved;
        ticket.insuranceAmount = data.insuranceAmount;
        ticket.proformaAmount = data.proformaAmount;
        ticket.customeoInlandCost = data.customeoInlandCost;

        ticket.customeoInlandCostCurrency = data.customeoInlandCostCurrency ? data.customeoInlandCostCurrency.split('/').at(-1) : null;
        ticket.customeoFreightCharges = data.customeoFreightCharges;
        ticket.customeoFreightChargesCurrency = data.customeoFreightChargesCurrency ? data.customeoFreightChargesCurrency.split('/').at(-1) : null;
        ticket.customeoInsuranceAmount = data.customeoInsuranceAmount;
        ticket.customeoInsuranceAmountCurrency = data.customeoInsuranceAmountCurrency ? data.customeoInsuranceAmountCurrency.split('/').at(-1) : null;
        ticket.dossCcsCreationDate = data.dossCcsCreationDate;
        ticket.userBackup = data.userBackup;
        ticket.palettisation = data.palettisation;
        ticket.isReserved = !!data.reserved;
        ticket.isTct = !!data.tct;
        ticket.isIdTPT = !!data.idTPT;
        ticket.isCrv = !!data.crv;
        ticket.isLoadingCommand = !!data.loadingCommand;

        ticket.billingComment = data.billingComment;

        return ticket;
    }

    serialize(data: Partial<Ticket>, context: any): serializedType<Ticket> {
        switch (context) {
            case 'kanban':
                return {
                    column: data.column,
                };
            case 'dossier':
                return {
                    badCompany: data.badCompany,
                    badCompanyDate: data.badCompanyDate ?? null,
                    badCompanyRequest: data.badCompanyRequest,
                    bae: data.bae,
                    bas: data.bas,
                    basDate: data.basDate ?? null,
                    baeDate: data.baeDate ?? null,
                    bookingRequest: data.bookingRequest,
                    commercialRequest: data.commercialRequest,
                    control: data.control ?? null,
                    copy: data.copy,
                    copyDate: data.copyDate ?? null,
                    customeoInlandCost: data.customeoInlandCost,
                    customeoInlandCostCurrency: data.customeoInlandCostCurrency ? `/api/customeo_currencies/${data.customeoInlandCostCurrency}` :null,
                    customeoFreightCharges: data.customeoFreightCharges,
                    customeoFreightChargesCurrency: data.customeoFreightChargesCurrency ? `/api/customeo_currencies/${data.customeoFreightChargesCurrency}` : null,
                    customeoInsuranceAmount: data.customeoInsuranceAmount,
                    customeoInsuranceAmountCurrency: data.customeoInsuranceAmountCurrency ? `/api/customeo_currencies/${data.customeoInsuranceAmountCurrency}` : null,
                    customerBoardingConfirmation: data.customerBoardingConfirmation,
                    customerBoardingForecast: data.customerBoardingForecast,
                    customsGreenLights: data.customsGreenLights,
                    customsPreparation: data.customsPreparation,
                    customsPreparationDate: data.customsPreparationDate ?? null,
                    customsTransfer: data.customsTransfer,
                    customsVisit: data.customsVisit,
                    customsVisitDate: data.customsVisitDate,
                    dossCcsCreation: data.dossCcsCreation,
                    expressRelease: data.expressRelease,
                    loadingCommand: data.loadingCommand ?? null,
                    originalsDocumentDepositedToCompanyAndPayment: data.originalsDocumentDepositedToCompanyAndPayment,
                    quaysideView: data.quaysideView,
                    quaysideViewDate: data.quaysideViewDate ?? null,
                    quotation: data.quotation,
                    receiptAcknowledgment: data.receiptAcknowledgment,
                    releaseRequest: data.releaseRequest,
                    requestCustomsInspection: data.requestCustomsInspection,
                    requestOriginalsToCustomer: data.requestOriginalsToCustomer,
                    requestTraction: data.requestTraction,
                    returnAnalysis: data.returnAnalysis,
                    returnAnalysisDate: data.returnAnalysisDate,
                    sycoscan: data.sycoscan,
                    terminalReturnRequest: data.terminalReturnRequest,
                    unloadingCommand: data.unloadingCommand,
                    unloadingDone: data.unloadingDone,
                    unloadingReport: data.unloadingReport,
                    unloadingReportReceivedDate: data.unloadingReportReceivedDate,
                    wareControl: data.wareControl,
                    idTPT: data.idTPT ?? null,
                    crv: data.crv ?? null,
                    tct: data.tct ?? null,
                    carriersName: data.carriersName ?? null,
                    paletteSizeAndType: data.paletteSizeAndType ?? null,
                    reserved: data.reserved ?? null,
                    insuranceAmount: data.insuranceAmount,
                    proformaAmount: data.proformaAmount,
                    preBilled: data.preBilled,
                    originals: data.originals,
                    originalsDate: data.originalsDate ?? null,
                    dossCcsCreationDate: data.dossCcsCreationDate ?? null,
                    userBackup: data.userBackup,
                    palettisation: data.palettisation,
                    insuranceSubscription: data.insuranceSubscription,
                    billingComment: data.billingComment,
                };
            default:
                return data;
        }
    }
}();

export {TicketNormalizer};
