import {User} from "./User";
import {Shipment} from "./Shipment";
import {Commande} from "./Commande";

export class Customer {
    id: number = 0
    createdBy: User | null = null
    updatedBy: User | null = null
    deletedBy: User | null = null
    idUnique: string | null = null
    name: string | null = null
    email: string | null = null
    bic: string | null = null
    iban: string | null = null
    // commercial: Contact | null = null
    // exploitation: Contact | null = null
    // customs: Contact | null = null
    // accountability: Contact | null = null
    // itAdmin: Contact | null = null
    // exploitationExport: Contact | null = null
    // responsable: Contact | null = null
    oiId: number | null = null
    shipments: Shipment[] | null = null
    commandes: Commande[] | null = null

    constructor(id: number, name: string | null) {
        this.id = id;
        this.name = name;
    }
}

export type CustomerType = Customer;