import {normalizedType, Normalizer} from "./normalizer";
import {ShipmentCustomeo} from "../../model/ShipmentCustomeo";

const ShipmentCustomeoNormalizer = new class implements Normalizer<ShipmentCustomeo>{
    normalize(data: normalizedType<ShipmentCustomeo>): ShipmentCustomeo {
        const shipmentCustomeo = new ShipmentCustomeo(
            data.id
        );

        shipmentCustomeo.aheadOfTime = data.aheadOfTime ?? false;
        shipmentCustomeo.dateTimeReady = data.dateTimeReady;
        shipmentCustomeo.insuranceAmountCurrency = data.insuranceAmountCurrency;
        shipmentCustomeo.freightChargesCurrency = data.freightChargesCurrency;
        shipmentCustomeo.inlandCurrency = data.inlandCurrency;
        shipmentCustomeo.inlandCost = data.inlandCost;
        shipmentCustomeo.freightCharges = data.freightCharges;
        shipmentCustomeo.insuranceAmount = data.insuranceAmount;
        shipmentCustomeo.baeClearedDate = data.baeClearedDate ? new Date(data.baeClearedDate) : null;
        shipmentCustomeo.createdAt = new Date(data.createdAt);
        shipmentCustomeo.createdBy = data.createdBy;
        shipmentCustomeo.clearance = data.clearance ?? false;
        shipmentCustomeo.preparation = data.preparation ?? false;
        shipmentCustomeo.estimation = data.estimation ?? false;
        shipmentCustomeo.urgent = data.urgent ?? false;
        shipmentCustomeo.multiple = data.multiple ?? false;
        shipmentCustomeo.attachDocuments = data.attachDocuments ?? null;
        shipmentCustomeo.brokerStatus = data.brokerStatus ?? null;
        shipmentCustomeo.customsStatus = data.customsStatus ?? null;
        shipmentCustomeo.reference = data.reference ?? null;
        shipmentCustomeo.freeInformation = data.freeInformation ?? null;
        shipmentCustomeo.inNameOf = data.inNameOf ?? null;
        shipmentCustomeo.derudderReference = data.derudderReference ?? null;
        shipmentCustomeo.type = data.type ?? null;
        shipmentCustomeo.clientAccount = data.clientAccount ?? null;
        shipmentCustomeo.portCustomsOffice = data.portCustomsOffice ?? null;
        shipmentCustomeo.exitCustomsOffice = data.exitCustomsOffice ?? null;
        shipmentCustomeo.team = data.team ?? null;
        shipmentCustomeo.customeoUuid = data.customeoUuid ?? null;
        shipmentCustomeo.brokerStatusStyles = data.brokerStatusStyles;
        shipmentCustomeo.customsStatusStyles = data.customsStatusStyles;

        return shipmentCustomeo;
    }
}();

export { ShipmentCustomeoNormalizer };
