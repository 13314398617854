import React, {useCallback, useContext, useEffect, useState} from 'react';
import FilePreview, {FileType} from "./FilePreview";
import {Button} from "react-bootstrap";
import FileUploadZone from "./FileUploadZone";
import useUpload from "../../hooks/useUpload";
import {ShipmentAttachedFileNormalizer} from "../../services/normalizer/ShipmentAttachedFileNormalizer";
import {ShipmentAttachedFile} from "../../model/ShipmentAttachedFile";
import {FileManagerProps} from "../app/App/FileManager";
import {FileResource} from "../../model/interface/FileResource";
import {Shipment} from "../../model/Shipment";
import {FlashContext} from "../../context/FlashContext";

interface FileUploaderProps {
    onFileUpload?: (files: ShipmentAttachedFile[]) => void;
    showPreview?: boolean;
    type: FileManagerProps<FileResource>['type']
    shipmentId: Shipment['id']
    confirmUpload?: boolean
}

const isShipmentFile = (file: ShipmentAttachedFile | false): file is ShipmentAttachedFile => !!file

const FileUploader = (props: FileUploaderProps) => {
    const {
        onFileUpload,
        shipmentId,
        showPreview = true,
        confirmUpload = true,
        type
    } = props

    const [filesToUpload, setFilesToUpload] = useState<FileType[]>([]);

    const {upload} = useUpload('shipment_attached_files', ShipmentAttachedFileNormalizer);

    const {addSuccess, addError} = useContext(FlashContext);

    const addFiles = (files: FileType[]) => {
        setFilesToUpload( oldFilesToUpload => oldFilesToUpload.concat(files));
    };

    const removeFile = (fileIndex: number) => {
        setFilesToUpload(oldFilesToUpload => {
            oldFilesToUpload.splice(fileIndex, 1);
            return oldFilesToUpload;
        });
    };

    const startUpload = useCallback(async () => {
        if (!shipmentId) {
            return;
        }

        const shipmentAttachedFiles = filesToUpload.map(file => {
            if (!shipmentId) {
                return null;
            }
            const attachedFile = new ShipmentAttachedFile(0, file.name);

            attachedFile.file = file
            attachedFile[type] = shipmentId.toString()

            return attachedFile;
        });

        try {
            const res = await Promise.all(
                shipmentAttachedFiles.map(attachedFile => {
                    if (!attachedFile) {
                        return false;
                    }

                    return upload(attachedFile);
                })
            );

            if (onFileUpload) {
                onFileUpload(res.filter(isShipmentFile));
            }
        } catch (e) {
            addError('Erreur : ' + e)
        }

        addSuccess('Fichiers ajoutés avec succès');

        setFilesToUpload([]);
    }, [addError, addSuccess, filesToUpload, onFileUpload, shipmentId, type, upload]);

    useEffect(() => {
        if (!confirmUpload && filesToUpload.length !== 0) {
            startUpload();
        }
    }, [confirmUpload, filesToUpload, startUpload]);

    return (
        <>
            <FileUploadZone onFileAdd={addFiles}/>

            {
                showPreview && confirmUpload
                    ? (
                        <FilePreview files={filesToUpload} onRemove={removeFile}/>
                    )
                    : null
            }

            {
                filesToUpload.length !== 0 && confirmUpload
                    ? (
                        <Button className={'mt-2'} variant={'secondary'} size={"lg"} onClick={startUpload}>
                            Téléverser
                        </Button>
                    )
                    : null
            }
        </>
    );
};

export default FileUploader;
