import React, { InputHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import {UseFormRegister} from "react-hook-form";

interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
    containerClass?: string,
    inputClasses?: string,
    labelClasses?: string,
    label?: string,
    name: string,
    placeholder?: string,
    register: UseFormRegister<any>,
    defaultValue?: string | number | ReadonlyArray<string> | undefined
}

const EmailInput = ({name, label, containerClass, register, inputClasses, labelClasses, placeholder, defaultValue}: EmailInputProps) => {

    const errors = {[name]: {message: ''}};

    return (
        <Form.Group className={containerClass}>
            {label ? <Form.Label className={labelClasses}>{label}</Form.Label> : null}

            <Form.Control
                type={"email"}
                defaultValue={defaultValue}
                placeholder={placeholder}
                id={name}
                className={inputClasses}
                {...register(name)}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message?.toString()}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};

export default EmailInput;
