import React from 'react';
import {Button} from 'react-bootstrap';

interface ButtonProps {
    buttonVariant?: string,
    buttonType?: "button" | "submit" | "reset" | undefined,
    buttonLabel?: string,
    buttonIcon?: string,
    classes?: string,
    handleClickEvent?: any,
    disabled?: boolean
}

const ButtonComponent = ({buttonVariant, buttonType, buttonLabel, buttonIcon, classes, handleClickEvent, disabled = false}: ButtonProps) => {
    return (
        <>
            <Button variant={buttonVariant} type={buttonType} className={classes} onClick={handleClickEvent} disabled={disabled}>
                {buttonIcon ?
                    <i className={buttonIcon}></i>
                    : null
                }
                {
                    buttonLabel ?
                        <span className={buttonIcon ? "ps-1" : ""}>{buttonLabel}</span>
                        : null
                }
            </Button>
        </>
    )
}

export default ButtonComponent;