import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Col, Row} from 'react-bootstrap';

export interface FileType extends File {
    preview?: string;
    formattedSize?: string;
}

interface FilePreviewProps {
    files: FileType[],
    onRemove?: (index: number) => void
}

const FilePreview = ({files, onRemove}: FilePreviewProps) => {

    return (
        <div className="dropzone-previews mt-3">
            {files.map((f, i) => {
                return (
                    <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                        <div className="p-2">
                            <Row className="align-items-center">
                                {
                                    f.preview
                                        ? (
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                />
                                            </Col>
                                        )
                                        : (
                                            <Col className="col-auto">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title bg-primary rounded">
                                                        {f.name.split('.').reverse()[0]}
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                }
                                <Col className="ps-0">
                                    <Link to="#" className="text-muted fw-bold">
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                </Col>
                                {
                                    onRemove
                                        ? (
                                            <Col className="text-end">
                                                <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                                                    <i className="dripicons-cross" onClick={() => onRemove(i)}></i>
                                                </Link>
                                            </Col>
                                        )
                                        : null
                                }
                            </Row>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export default FilePreview;
