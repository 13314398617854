import {useContext, useMemo} from "react";
import {TokenContext} from "../context/TokenContext";
import axios from "axios";
import {Normalizer} from "../services/normalizer/normalizer";
import {FileResource} from "../model/interface/FileResource";

export interface Filesource<T extends FileResource> {
    upload(resource: T, context?: any): Promise<T | false>
}


export default function useUpload<ResourceT extends FileResource>(namespace: string, normalizer: Normalizer<ResourceT>): Filesource<ResourceT> {

    const {token} = useContext(TokenContext);

    return useMemo(() => {

        const url = process.env.REACT_APP_API_URL;

        const headers = {
            Accept: 'application/json',
            Authorization: 'Token ' + token,
            'Content-Type': 'multipart/form-data',
        };

        return ({
            async upload(resource, context) {
                try {
                    const res = await axios.post<ResourceT>(
                        `${url}/api/${namespace}`,
                        normalizer.serialize ? normalizer.serialize(resource, context) : resource, {
                            headers: headers,
                        }
                    )

                    if (normalizer.normalize) {
                        return normalizer.normalize(res.data);
                    } else {
                        return res.data;
                    }
                } catch (rejection) {
                    console.error(rejection);
                }

                return false;
            }
        });
    }, [namespace, token, normalizer])
}
