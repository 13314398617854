import React from 'react';

import {routes} from "../../navigations/route/main";
import MainMenu from "../Menu/MainMenu";
import {RoutesProps} from "../../navigations/router.config";
import ConsultationMenu from "../ConsultationMenu/ConsultationMenu";

const RightSideBar = () => {
    const [root] = routes;
    const mainRouteChildren: RoutesProps[] = [];
    root.children && root.children.forEach((value, index) => {
        if (value.position === 1) mainRouteChildren.push(value);
    })
    return (
        <React.Fragment>
            <ConsultationMenu items={<MainMenu routes={mainRouteChildren} />} title={"Paramètres"}  />
        </React.Fragment>
    );
};

export default RightSideBar;