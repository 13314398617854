import {Form, FormControlProps, InputGroup} from "react-bootstrap";
import React, {ReactNode} from "react";
import {Control, Controller} from "react-hook-form";

interface NumberInputProps extends FormControlProps {
    containerClass?: string
    label?: string,
    name: string,
    placeholder?: string,
    control: Control
    inputGroupText?: ReactNode
}

export default function NumberInput(props: NumberInputProps) {

    const {
        name,
        label,
        containerClass,
        placeholder,
        control,
        inputGroupText,
        ...inputProps
    } = props;

    const labelClassName = '';
    const className = '';
    const errors = {[name]: {message: ''}};
    return (
        <Form.Group className={containerClass}>
            {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
            <Controller
                control={control}
                name={name}
                disabled={props.disabled}
                defaultValue={''}
                render={
                    (
                        {
                            field: {onChange, name, value},
                        }
                    ) => {
                        return (
                            <InputGroup>
                                <Form.Control
                                    type={"number"}
                                    name={name}
                                    className={className}
                                    placeholder={placeholder}
                                    onChange={(e) => onChange(!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : e.target.value)}
                                    value={value ?? ''}
                                    {...inputProps}
                                />
                                {
                                    inputGroupText
                                        ? <InputGroup.Text>{inputGroupText}</InputGroup.Text>
                                        : null
                                }
                            </InputGroup>
                        )
                    }
                }
            />


            {
                errors && errors[name]
                    ? (
                        <Form.Control.Feedback type="invalid">
                            {errors[name]?.message?.toString()}
                        </Form.Control.Feedback>
                    ) : null
            }
        </Form.Group>
    )
}
