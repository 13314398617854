import {createContext, ReactNode} from "react";

export interface TokenContextInterface {
    token: string|null,
}

const initialState: TokenContextInterface = {
    token: null,
};

export const TokenContext = createContext<TokenContextInterface>(initialState);

export const TokenContextProvider = ({token, children}: {token: string | null, children: ReactNode}) => {

    const value = {
        token: token,
    };

    return <TokenContext.Provider value={value} children={children}/>
}