import React, {ReactElement, useEffect, useState} from "react";
import {FilterType} from "../hooks/useDatasource";
import {SubmitHandler} from "react-hook-form/dist/types/form";
import {useMatches} from "react-router-dom";

export type renderFilterProps = {
    onFilter: SubmitHandler<any>,
    onReset: () => void
    defaultValues: FilterType,
}

export interface renderFilterableProps {
    searchFilter?: FilterType
}

export interface FilterControllerProps {
    renderFilterable: (props: renderFilterableProps) => ReactElement
    renderFilter: (props: renderFilterProps) => ReactElement
    filterDefault?: FilterType
}

export default function FilterController (props: FilterControllerProps) {

    const {
        renderFilterable,
        renderFilter,
        filterDefault = {}
    } = props

    const matches = useMatches();

    const lastMatch = matches.at(-1);
    const [filters, setFilters] = useState<FilterType>(filterDefault || {});

    const onFilter = (data: any) => {
        const newFilters = Object.keys(data)
            .filter((key: keyof typeof data) => typeof data[key] === 'boolean' ? true : Boolean(data[key]))
            .reduce((acc, key) => ({...acc, ...{[key]: data[key]}}), {});

        setFilters( {...Object.assign({}, filterDefault || {}, newFilters)});
        localStorage[lastMatch?.id + 'Filter'] = JSON.stringify(newFilters);
    }

    const onReset = () => {
        if (filters === filterDefault) {
            return;
        }
        setFilters(filterDefault || {});
        localStorage[lastMatch?.id + 'Filter'] = JSON.stringify({});
    }

    useEffect(() => {
        const localStoredFilters = JSON.parse(localStorage[lastMatch?.id + 'Filter'] ?? '{}');
        setFilters(Object.assign({}, filterDefault || {}, localStoredFilters));
    }, [filterDefault, lastMatch]);

    const filter = renderFilter({onFilter, onReset, defaultValues: filters});
    const filterable = renderFilterable({ searchFilter: filters});

    return <>
        {filter}
        {filterable}
    </>
}
