import useDatasource, {FilterType} from "../../hooks/useDatasource";
import {Normalizer} from "../../services/normalizer/normalizer";
import {Nameable} from "../../model/interface/Nameable";
import {useCallback, useEffect, useState} from "react";
import SelectInput, {SelectInputOptions, SelectInputProps} from "./SelectInput";
import {InputActionMeta} from "react-select";
import {UseFormGetValues} from "react-hook-form";


interface AsyncSelectInputProps<ResourceT extends Nameable> extends Omit<SelectInputProps, 'selectOptions'> {
    namespace: string
    normalizer: Normalizer<ResourceT>
    getValues: UseFormGetValues<any>
    asyncFilters?: FilterType,
    getChoiceValue?: (el: ResourceT) => any
}

export default function AsyncSelectInput<ResourceT extends Nameable> (props: AsyncSelectInputProps<ResourceT>) {
    const {
        namespace,
        normalizer,
        getValues,
        asyncFilters,
        getChoiceValue,
        ...selectProps
    } = props;

    const [options, setOptions] = useState<SelectInputOptions[]>([]);

    const {index, lightGet} = useDatasource(namespace, normalizer);

    const defaultGetChoiceValue = useCallback(
        (el: ResourceT) => `/api/${namespace}/${el.id}`,
        [namespace]
    );

    const refreshOption = useCallback(async (input?: string, meta?: InputActionMeta, controller?: AbortController) => {
        try {
            const res = await index({...{name: input}, ...asyncFilters}, {name: 'ASC'}, controller);

            const currentValue = getValues(props.name);

            // ajoute l'élément courant dans la liste des options dans le cas où il y a de la pagination
            // si celui - ci ne s'y trouve pas
            if (currentValue && res.findIndex(el => getChoiceValue ? getChoiceValue(el) : defaultGetChoiceValue(el) === currentValue) === -1) {
                const current = await lightGet(currentValue, controller);

                res.push(current);
            }

            setOptions(res
                .map(
                    el => ({
                        label: el.name,
                        value: getChoiceValue ? getChoiceValue(el) : defaultGetChoiceValue(el)
                    })
                ));
        } catch (err) {
        }

        return input;
    }, [asyncFilters, defaultGetChoiceValue, getChoiceValue, getValues, index, lightGet, props.name]);

    useEffect(() => {
        const controller = new AbortController();

        refreshOption(undefined, undefined, controller);

        return () => {
            controller.abort()
        }
    }, [refreshOption]);

    return (
        <SelectInput
            selectOptions={options}
            onInputChange={(input, meta) => {
                // on encapsule l'appel de la fonction, car la valeur de retour du "onInputChange" est exploité
                // malgré le fait qu'elle soit typé "void"
                refreshOption(input, meta);
            }}
            {...selectProps}
        />
    )
}
