import {Form, FormControlProps, InputGroup} from 'react-bootstrap';
import React from 'react';
import {Control, Controller} from "react-hook-form";

interface TextAreaInputProps extends FormControlProps {
    containerClass?: string
    label?: string,
    name: string,
    placeholder?: string,
    control: Control
    resourceValue?: string | number
    inputGroupText?: string
    rows?: number
}

export default function TextAreaInput(props: TextAreaInputProps) {

    const {
        name,
        label,
        containerClass,
        placeholder,
        control,
        inputGroupText,
        rows = 3,
        ...inputProps
    } = props;

    const labelClassName = '';
    const errors = {[name]: {message: ''}};
    return (
        <Form.Group className={containerClass}>
            {label
                ? <Form.Label className={labelClassName}>{label}</Form.Label>
                : null}
            <Controller
                control={control}
                name={name}
                disabled={props.disabled}
                defaultValue={''}
                render={
                    (
                        {
                            field: {onChange, name, value},
                        },
                    ) => {
                        return (
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    as='textarea'
                                    rows={rows}
                                    placeholder={placeholder}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    {...inputProps}
                                />
                                {inputGroupText
                                    ?
                                    <InputGroup.Text>{inputGroupText}</InputGroup.Text>
                                    : null}
                            </InputGroup>
                        );
                    }}
            />


            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message?.toString()}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
}
