import {ShipmentCustomeo} from "../../../model/ShipmentCustomeo";
import {FormController} from "../../../forms/FormController";
import {ShipmentCustomeoNormalizer} from "../../../services/normalizer/ShipmentCustomeoNormalizer";
import CustomeoType from "../../../forms/app/types/CustomeoType";
import {useCallback, useContext, useState} from "react";
import {removeEmptyFields} from "../../../utils";
import useDatasource, {ApiError, isApiError} from "../../../hooks/useDatasource";
import {FlashContext} from "../../../context/FlashContext";

interface CustomeoTypeProps {
    customeo: ShipmentCustomeo
    disabled?: boolean
    onCreateOk: (customeo: ShipmentCustomeo, index: number) => void
    index: number
}

export default function Customeo({customeo, disabled = true, onCreateOk, index}: CustomeoTypeProps) {

    const [error, setError] = useState<ApiError<ShipmentCustomeo> | null>(null);

    const {create} = useDatasource('shipment_customeos', ShipmentCustomeoNormalizer);

    const {addSuccess, addError} = useContext(FlashContext);

    const createCustomeo = useCallback(
        async (data: ShipmentCustomeo) => {
            removeEmptyFields(data);

            try {
                const res = await create(data, '');

                onCreateOk(res, index);

                addSuccess('Bon de douane créé avec succès.');
            } catch (error) {
                addError('Une erreur est survenu');
                if (
                    isApiError<ShipmentCustomeo>(error)
                ) {
                    setError(error);
                } else if (
                    error
                    && typeof error === 'object'
                    && 'detail' in error
                    && typeof error.detail === 'string'
                ) {
                    addError(error.detail);
                }
            }
        }
        , [create, onCreateOk, index, addSuccess, addError]
    )


    return <FormController
        namespace={'shipment_customeos'}
        normalizer={ShipmentCustomeoNormalizer}
        resource={customeo}
        parentErrors={error?.violations}
        renderForm={(props) => (<CustomeoType {...props} disabled={disabled}/>)}
        handleSubmitEvent={() => (data) => createCustomeo(data)}
    />;
}
