import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SimpleBar from 'simplebar-react';

// actions
import {hideRightSidebar} from '../../redux/actions';

// store
import {AppDispatch, RootState} from '../../redux/store';

import {Nav, Tab} from 'react-bootstrap';

interface ConsultationMenuProps {
    hideRightSidebar?: () => void;
    title?: string;
    children?: any;
    items?: any;
}

function ConsultationMenu({title, items}: ConsultationMenuProps) {

    const dispatch = useDispatch<AppDispatch>();
    const consultationMenuNodeRef: any = useRef(null);

    const isOpenConsultationMenu = useSelector((state: RootState) => (
        state.Layout.isOpenRightSideBar
    ));

    /**
     * Handle the click anywhere in doc
     */
    const handleOtherClick = useCallback(
        (e: any) => {
            if (isOpenConsultationMenu) {
                if (consultationMenuNodeRef &&
                    consultationMenuNodeRef.current &&
                    consultationMenuNodeRef.current.contains(e.target)) {
                    return;
                } else {
                    dispatch(hideRightSidebar());
                }
            }
        },
        [consultationMenuNodeRef, dispatch, isOpenConsultationMenu],
    );
    useEffect(() => {
        document.addEventListener('mousedown', handleOtherClick, false);
        return () => {
            document.removeEventListener('mousedown', handleOtherClick, false);
        };
    }, [handleOtherClick]);

    return (
        <React.Fragment>
            <div className="right-bar" ref={consultationMenuNodeRef}>
                <SimpleBar style={{maxHeight: '100%', zIndex: 10000}}
                           timeout={500} scrollbarMaxSize={320}>
                    <Tab.Container id="left-tabs-example"
                                   defaultActiveKey="rightBar">
                        <Nav variant="tabs"
                             className="nav-bordered nav-justified">
                            <Nav.Item className="py-2">
                                {title}
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="p-0">
                            <Tab.Pane eventKey="rightBar">
                                <div>{items}</div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </SimpleBar>

            </div>
            <div className="rightbar-overlay"></div>
        </React.Fragment>
    );

}

export default ConsultationMenu;