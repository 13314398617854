import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import LoginLayout from "../layouts/LoginLayout";
import TriptychLayout from "../layouts/TriptychLayout";
import {useEffect, useState} from "react";
import {FieldValues} from "react-hook-form";
import {TokenContextProvider} from "../context/TokenContext";
import axios from "axios";
import Spinner from "../components/Spinner";

const localStorageToken = 'Token';
const localStorageFullname = 'fullname';
const localStorageImpersonate = 'impersonate';

const url = process.env.REACT_APP_API_URL;
const debug = /*process.env.NODE_ENV === 'development' ? 'app_dev.php/' : */'';

function RootScreen() {
    const [login, setLogin] = useState<string | null>(localStorage.getItem(localStorageToken));
    const [error, setError] = useState<string | null | undefined>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const apiToken = searchParams.get('api_token');

    const handleLogin = (formData: FieldValues) => {
        axios.post(`${url}/${debug}workflow-login`,
            {
                _username: formData.username,
                _password: formData.password
            }, {
                headers: {Accept: 'application/json'},
            }).then((res) => {
            const fullname = res.data.firstname + ' ' + res.data.lastname;
            localStorage.setItem(localStorageToken, res.data.token);
            localStorage.setItem(localStorageFullname, fullname);
            localStorage.setItem(localStorageImpersonate, 'false');

            setLogin(res.data.token);
        }).catch((err) => {
            console.log(err);
            setError(err);
            return false;
        })
    }

    const checkToken = (apiToken: string) => {
        if (apiToken.length === 128) {
            setLoading(true);
            const body = JSON.stringify({_token: apiToken});
            axios
                .post(`${url}/${debug}check_token`, body, {})
                .then((res) => {
                    setLoading(false);
                    localStorage.setItem(localStorageToken, apiToken);
                    localStorage.setItem(localStorageFullname, res.data.firstname + ' ' + res.data.lastname);
                    localStorage.setItem(localStorageImpersonate, 'true');
                    setLogin(apiToken);
                    navigate('/');
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        }
    }
    const handleLogout = () => {
        localStorage.removeItem(localStorageToken)
        localStorage.removeItem(localStorageFullname)
        localStorage.removeItem(localStorageImpersonate)
        setLogin(null);
    }

    useEffect(() => {
        if (apiToken) {
            checkToken(apiToken);
        }
    // eslint-disable-next-line
    }, [apiToken]);

    if (loading) {
        //@TODO: Add a splash screen
        return (
            <div
                className={'text-center pt-2 mb-0'}><Spinner/>
            </div>
        );
    }

    if (login && !apiToken) {
        return (
            <TokenContextProvider token={login}>
                <TriptychLayout handleLogout={handleLogout}>
                    <Outlet/>
                </TriptychLayout>
            </TokenContextProvider>
        );
    }


    return (
        <LoginLayout handleLogin={handleLogin} error={error}/>
    );
}

export default RootScreen;
