import {User} from "./User";
import {Shipment} from "./Shipment";
import AbstractComment from "./AbstractComment";

export class Comment extends AbstractComment {
    shipment: string | Shipment | null = null;
    updatedBy: User | null = null
    deletedBy: User | null = null
    createdByClient: boolean = false
}

export type CommentType = Comment;