import {Shipment} from './Shipment';
import {ColumnExportConstants, ColumnImportConstants, TicketCategoryConstants,} from '../constants/model';
import {Ticket as TicketInterface} from './interface/Ticket';
import {CustomeoCurrency} from "./CustomeoCurrency";

export enum TicketControlEnum {
    SIVEP = 'sivep',
    HSA = 'hsa',
    BIO = 'bio',
}

export class Ticket implements TicketInterface {
    receiptAcknowledgment: boolean = false;
    quotation: boolean = false;
    commercialRequest: boolean = false;
    preBilled: boolean = false;
    insuranceSubscription: boolean = false;
    cifFob: boolean = false;
    releaseRequest: boolean = false;

    //Main Transport Action
    bookingRequest: boolean = false;
    customerBoardingForecast: boolean = false;
    customerBoardingConfirmation: boolean = false;
    copy: boolean = false;
    copyDate: Date | null = null;
    expressRelease: boolean = false;
    originals: boolean = false;
    requestOriginalsToCustomer: boolean = false;
    originalsDate: Date | null = null;
    requestOriginalsToCustomerDate: Date | null = null;
    customsPreparation: boolean = false;
    customsPreparationDate: Date | null = null;
    dossCcsCreation: boolean = false;
    dossCcsCreationDate: Date | null = null;
    dossCcsFFCreationDate: Date | null = null;
    ccsClearance: boolean = false;
    originalsDocumentDepositedToCompanyAndPayment: boolean = false;
    badCompany: boolean = false;
    badCompanyRequest: boolean = false;
    badCompanyDate: Date | null = null;
    badCompanyReceivedOn: Date | null = null;
    customsTransfer: boolean = false;
    aheadOfTime: boolean = false;
    quaysideView: boolean = false;
    quaysideViewDate: Date | null = null;
    triggerMyCustomsOkForClearance: boolean = false;
    requestCustomsInspection: boolean = false;
    control: TicketControlEnum | null = null;
    sycoscan: boolean = false;
    bae: boolean = false;
    baeDate: Date | null = null;
    idTPT: Date | null = null;
    crv: Date | null = null;
    tct: string | null = null;
    bas: boolean = false;
    basDate: Date | null = null;

    //Unloading Action
    requestUnloading: boolean = false;
    requestTraction: boolean = false;
    unloadingCommand: boolean = false;
    terminalReturnRequest: boolean = false;
    customsVisit: boolean = false;
    customsVisitDate: Date | null = null;
    customsGreenLights: boolean = false;
    wareControl: boolean = false;
    returnAnalysis: boolean = false;
    returnAnalysisDate: Date | null = null;
    unloadingDone: boolean = false;
    unloadingReport: boolean = false;
    unloadingReportReceivedDate: Date | null = null;
    loadingCommand: Date | null = null;
    customeoInlandCost: number = 0;
    customeoInlandCostCurrency: CustomeoCurrency | string | null = null;
    customeoFreightCharges: number = 0;
    customeoFreightChargesCurrency: CustomeoCurrency | string | null = null;
    customeoInsuranceAmount: number = 0;
    customeoInsuranceAmountCurrency: CustomeoCurrency | string | null = null;
    paletteSizeAndType: string | null = null;
    reserved: string | null = null;
    carriersName: string | null = null;
    palettisation: boolean = false;
    proformaAmount: number = 0;
    insuranceAmount: number = 0;
    userBackup: boolean = false;
    isReserved: boolean = false;
    isTct: boolean = false;
    isIdTPT: boolean = false;
    isCrv: boolean = false;
    isLoadingCommand: boolean = false;
    billingComment: string | null = null;

    constructor(
        readonly id: number,
        public shipment: Shipment,
        public column: ColumnImportConstants | ColumnExportConstants,
        public order: number | null,
        public category: TicketCategoryConstants,
        public dateTimeReady: Date | null,
    ) {
    }
}

export type TicketType = Ticket;