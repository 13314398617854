import {Form, FormControlProps, InputGroup} from 'react-bootstrap';
import React from 'react';
import {Control, Controller} from "react-hook-form";

interface TextInputProps extends Omit<FormControlProps, 'value'> {
    containerClass?: string
    label?: string,
    name: string,
    placeholder?: string,
    control: Control
    inputGroupText?: string
}

export default function TextInput(props: TextInputProps) {

    const {
        name,
        label,
        containerClass,
        placeholder,
        control,
        inputGroupText,
        ...inputProps
    } = props
    const errors = {[name]: {message: ''}};
    return (
        <Form.Group className={containerClass}>
            {label
                ? <Form.Label>{label}</Form.Label>
                : null}
            <Controller
                control={control}
                name={name}
                defaultValue={''}
                disabled={props.disabled}
                render={
                    (
                        {
                            field: {onChange, name, value},
                            fieldState: {error},
                        },
                    ) => {
                        return (
                            <>
                                <InputGroup>
                                    <Form.Control
                                        type={'text'}
                                        name={name}
                                        placeholder={placeholder}
                                        onChange={onChange}
                                        value={value ?? ''}
                                        {...inputProps}
                                    />
                                    {inputGroupText
                                        ?
                                        <InputGroup.Text>{inputGroupText}</InputGroup.Text>
                                        : null}
                                </InputGroup>
                                {
                                    error
                                        ? (
                                            <div className="text-danger">
                                                {error?.message}
                                            </div>
                                        ) : null
                                }
                            </>
                        );
                    }}
            />


            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message?.toString()}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
}
