import {normalizedType, Normalizer} from "./normalizer";
import {InvoicePdf} from "../../model/InvoicePdf";
import {InvoiceNormalizer} from "./InvoiceNormalizer";

const InvoicePdfNormalizer = new class implements Normalizer<InvoicePdf>{
    normalize(data: normalizedType<InvoicePdf>): InvoicePdf {

        const invoice = data.invoice ? InvoiceNormalizer.normalize(data.invoice) : null;

        const invoicePdf = new InvoicePdf(
            invoice
        );

        invoicePdf.id = data.id;
        invoicePdf.invoiceNumber = data.invoiceNumber;
        invoicePdf.file = data.file;
        invoicePdf.fileName = data.fileName;
        invoicePdf.caseNumber = data.caseNumber;
        invoicePdf.invoiceDate = invoice?.invoiceDate ? new Date(invoice.invoiceDate) : null;
        invoicePdf.name = invoice?.name ?? null;

        return invoicePdf;
    }
}();

export { InvoicePdfNormalizer };
