import React, {useCallback, useContext, useEffect, useState} from "react";
import useDatasource from "../../../../hooks/useDatasource";
import {FlashContext} from "../../../../context/FlashContext";
import {FormController} from "../../../../forms/FormController";
import {Form} from "react-bootstrap";
import ButtonComponent from "../../../ButtonComponent";
import classNames from "classnames";
import * as Chat from "../../../../forms/app/types/Import/Chat";
import {Comment} from "../../../../model/Comment";
import {CommentNormalizer} from "../../../../services/normalizer/ShipmentCommentaireNormalizer";
import {Shipment} from "../../../../model/Shipment";

interface ChatTabProps {
    shipment: Shipment | null
}

const ChatTab = ({shipment}: ChatTabProps) => {
    const [comments, setComments] = useState<Comment[]>([]);
    const {index, create} = useDatasource<Comment>('shipment_comments', CommentNormalizer);
    const [isLoading, setIsLoading] = useState(true);

    const {addSuccess} = useContext(FlashContext);

    const shipmentId = shipment?.id;

    const addToBeginning = (data: Comment) => {
        setComments((prevState) => {
            prevState.unshift(data);
            return prevState;
        });
    }

    const fetchChat = useCallback((controller?: AbortController) => {
        if (shipmentId) {
            index({shipment: shipmentId}, {id: "DESC"}, controller)
                .then(setComments)
                .then(() => setIsLoading(false))
                .catch(console.log)
        }
    }, [index, shipmentId]);

    const postChat = useCallback(
        async (data: Comment) => {
            setIsLoading(true);
            data.shipment = 'api/shipments/' + shipment?.id;

            try {
                const newComment = await create(data, 'dossier');
                addSuccess('Chat envoyé avec succès.');

                if (newComment) {
                    addToBeginning(newComment)
                }
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        }
        , [create, addSuccess, shipment]
    )

    useEffect(() => {
        const controller = new AbortController()

        fetchChat(controller);

        return () => {
            controller.abort();
        }
    }, [fetchChat]);

    return (
        <>
            <FormController
                renderForm={(props) => <>
                    <Chat.ChatForm {...props}/>
                    <Form.Group className={"d-flex justify-content-end pe-2"}>
                        <ButtonComponent
                            buttonLabel={"Envoyer au client"}
                            buttonType={"submit"}
                            classes={"px-4"}
                            buttonIcon={classNames(
                                isLoading ? "spinner-border text-white spinner-border-sm" : "fas fa-paper-plane"
                            )}
                        />
                    </Form.Group>
                </>}
                namespace={'shipment_comments'}
                normalizer={CommentNormalizer}
                newResourceCreator={() => new Comment()}
                handleSubmitEvent={() => (data: Comment) => postChat(data)}
                resetOnSubmit={true}
            />
            <Chat.Chat
                resources={comments}
                isLoading={isLoading}
            />
        </>
    );
};

export default ChatTab;