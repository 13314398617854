import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {Identifiable} from "../model/interface/Identifiable";
import useDatasource, {FilterType, isHydra, OrderType} from "../hooks/useDatasource";
import {Normalizer} from "../services/normalizer/normalizer";
import axios from "axios";

export interface renderListingProps<ResourceT extends Identifiable> {
    resources: Array<ResourceT>,
    isLoading: boolean,
    loadNextBatch: () => void,
    totalItems: number | null,
}

export interface InfiniteListingControllerProps<ResourceT extends Identifiable> {
    title?: string | ReactElement
    renderListing: (props: renderListingProps<ResourceT>) => ReactElement
    namespace: string,
    normalizer: Normalizer<ResourceT>,
    filters?: FilterType,
    searchFilters?: FilterType,
    defaultNumPerPage?: number,
    setResources?: (resources: ResourceT[]) => void,
    resources?: ResourceT[],
    droppedItem?: {fromColumn: string, toColumn: string} | null,
    orderBy?: OrderType
}

export default function InfiniteListingController<ResourceT extends Identifiable> (props: InfiniteListingControllerProps<ResourceT>) {

    const {
        renderListing,
        title,
        namespace,
        normalizer,
        filters,
        defaultNumPerPage = 10,
        setResources: setControlledResources,
        resources: controlledResources,
        droppedItem,
        orderBy
    } = props

    const [resources, setResources] = useState<ResourceT[]>([]);

    const [resourcesPerPage] = React.useState(defaultNumPerPage);
    const [currentPage, setCurrentPage] = useState<string | null>(null);
    const [totalItems, setTotalItems] = useState<number | null>(null);

    const {isLoading, lastResponse, lightIndex, resetLastResponse} = useDatasource<ResourceT>(namespace, normalizer)

    const columnRef = useRef(filters?.column)

    const nextPage = useCallback(() => {
        // page de pagination possible si pas une hydra response
        if (!lastResponse || !isHydra(lastResponse)) {
            return;
        }

        // pas de page suivante
        if (!lastResponse["hydra:view"]["hydra:next"]) {
            return;
        }

        const nextParameter = lastResponse["hydra:view"]["hydra:next"].split('&').at(-1)
        setCurrentPage(`api/${namespace}?itemsPerPage=${resourcesPerPage}&${nextParameter}`)
    }, [lastResponse, namespace, resourcesPerPage]);

    useEffect(() => {
        setTotalItems((prevState) => {
            if (!lastResponse || !isHydra(lastResponse)) {
                return prevState;
            }

            return lastResponse['hydra:totalItems'];
        });
    }, [lastResponse]);

    useEffect(() => {
        setTotalItems((prevState) => {
            if (
                prevState
                && droppedItem
                && columnRef.current === droppedItem.fromColumn
            ) {
                return prevState - 1;
            }
            if (
                (prevState || prevState === 0)
                && droppedItem
                && columnRef.current === droppedItem.toColumn
            ) {
                return prevState + 1;
            }

            return prevState;
        });
    }, [droppedItem])

    useEffect(() => {
        setCurrentPage(`api/${namespace}?itemsPerPage=${resourcesPerPage}`);
        resetLastResponse();
    }, [filters, namespace, resourcesPerPage, resetLastResponse])

    useEffect(() => {
        if (!currentPage) {
            return;
        }
        const controller = new AbortController();

        (async () => {
            try {
                const result = await lightIndex(
                    currentPage ,filters, orderBy, controller, true
                );

                if (setControlledResources) {
                    setControlledResources(result)
                } else {
                    setResources((prevState) => [...prevState, ...result]);
                }

            } catch (err) {
                if (axios.isCancel(err)) {
                    return;
                }
                throw err;
            }
        })();

        return () => {
            controller.abort()
        }
    }, [lightIndex, filters, currentPage, setControlledResources, orderBy]);

    const listing = renderListing({ resources: controlledResources ? controlledResources : resources, isLoading, loadNextBatch: nextPage, totalItems: totalItems});

    return <>
        {title}
        {listing}
    </>
}
