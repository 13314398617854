import {renderFormProps} from "../../FormController";
import {ShipmentCustomeo} from "../../../model/ShipmentCustomeo";
import {Alert, Col, Row} from "react-bootstrap";
import SelectInput from "../../fields/SelectInput";
import React, {useEffect} from "react";
import {CustomeoTypeConstants} from "../../../constants/model";
import NumberInput from "../../fields/NumberInput";
import TextInput from "../../fields/TextInput";
import TextAreaInput from "../../fields/TextAreaInput";
import CheckboxInput from "../../fields/CheckboxInput";
import DateInput from "../../fields/DateInput";
import AsyncSelectInput from "../../fields/AsyncSelectInput";
import {CustomeoCompanyNormalizer} from "../../../services/normalizer/CustomeoCompanyNormalizer";
import {NameableNormalizer} from "../../../services/normalizer/NamableNormalizer";
import moment from "moment/moment";
import {parseInlineStyle} from "../../../utils/string";
import ButtonInput from "../../fields/ButtonInput";
import {CustomeoTeamNormalizer} from "../../../services/normalizer/CustomeoTeamNormalizer";

const currencyChoices = [
    {
        label: 'CAD',
        value: '/api/customeo_currencies/8'
    },
    {
        label: 'CNY',
        value: '/api/customeo_currencies/12'
    },
    {
        label: 'EUR',
        value: '/api/customeo_currencies/19'
    },
    {
        label: 'USD',
        value: '/api/customeo_currencies/61'
    },
    {
        label: 'ZAR',
        value: '/api/customeo_currencies/65'
    },
]


interface CustomeoTypeProps extends renderFormProps<ShipmentCustomeo> {
    disabled: boolean
}

export default function CustomeoType(props: CustomeoTypeProps) {

    const {
        resource,
        control,
        getValues,
        disabled,
        setValue,
        watch
    } = props;

    const [clearance, preparation, estimation] = watch(['clearance', 'preparation', 'estimation']);

    useEffect(() => {
        if (clearance) {
            setValue('preparation', false);
            setValue('estimation', false);
        }
    }, [clearance, setValue]);

    useEffect(() => {
        if (preparation) {
            setValue('clearance', false);
            setValue('estimation', false);
        }
    }, [preparation, setValue]);

    useEffect(() => {
        if (estimation) {
            setValue('clearance', false);
            setValue('preparation', false);
        }
    }, [estimation, setValue]);

    return (
        <>
            {
                disabled
                ? (
                    <>
                        <Alert variant='success'>
                            Submit for {resource.submitType} le {moment(resource.createdAt).format('DD/MM/yy à HH:mm')} par {resource.createdBy?.fullname}
                        </Alert>
                    </>
                ) : null
            }
            <Row>
                <Col md={4} sm={12}>
                    <SelectInput
                        isDisabled={disabled}
                        label={'Type'}
                        name={'type'}
                        singleValueClasses={'text-primary'}
                        control={control}
                        selectOptions={Object.entries(CustomeoTypeConstants).map(([, value]) => ({value, label: value}))}
                    />
                </Col>

                <Col md={{span: 4, offset: 4}} sm={12}>
                    <Row>
                        <Col sm={6} className={'p-1 bg-primary text-bg-primary border'}>
                            BROKER STATUS
                        </Col>
                        <Col sm={6} className={'p-1 bg-primary text-bg-primary border border-start-0'}>
                            CUSTOMS STATUS
                        </Col>
                        <Col sm={6} className={'p-1 border border-top-0'} style={parseInlineStyle(resource.brokerStatusStyles)}>
                            {resource.brokerStatus}
                        </Col>
                        <Col sm={6} className={'p-1 border border-top-0 border-start-0'} style={parseInlineStyle(resource.customsStatusStyles)}>
                            {resource.customsStatus}
                        </Col>
                    </Row>
                </Col>

                <Col md={4} sm={12}>
                    <TextInput
                        label="Référence Derudder *"
                        name={'derudderReference'}
                        control={control}
                        required={true}
                        disabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <TextInput
                        label="Votre référence *"
                        name={'reference'}
                        control={control}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={12}>
                    <TextInput
                        label="In name of *"
                        name={'inNameOf'}
                        control={control}
                        disabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <AsyncSelectInput
                        label="Company *"
                        placeholder="Company"
                        name='clientAccount'
                        control={control}
                        namespace={'customeo_companies'}
                        normalizer={CustomeoCompanyNormalizer}
                        getValues={getValues}
                        isDisabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <AsyncSelectInput
                        label="Customeo Team *"
                        placeholder="Customeo Team"
                        name='team'
                        control={control}
                        namespace={'groups'}
                        normalizer={CustomeoTeamNormalizer}
                        getValues={getValues}
                        asyncFilters={{exists: {customeoTeamId: true}}}
                        isDisabled={disabled}
                        getChoiceValue={el => (el.customeoTeamId)}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <Row sm={1}>
                        <Col>
                            <p className={'label'}>
                                Inland cost
                            </p>
                            <Row>
                                <Col lg={7}>
                                    <NumberInput
                                        name={'inlandCost'}
                                        control={control}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg={5}>
                                    <SelectInput
                                        name={'inlandCurrency'}
                                        control={control}
                                        selectOptions={currencyChoices}
                                        isDisabled={disabled}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <p className={'label'}>
                                Freight charges
                            </p>
                            <Row>
                                <Col lg={7}>
                                    <NumberInput
                                        name={'freightCharges'}
                                        control={control}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg={5}>
                                    <SelectInput
                                        name={'freightChargesCurrency'}
                                        control={control}
                                        selectOptions={currencyChoices}
                                        isDisabled={disabled}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <p className={'label'}>
                                Insurance amount
                            </p>
                            <Row>
                                <Col lg={7}>
                                    <NumberInput
                                        name={'insuranceAmount'}
                                        control={control}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg={5}>
                                    <SelectInput
                                        name={'insuranceAmountCurrency'}
                                        control={control}
                                        selectOptions={currencyChoices}
                                        isDisabled={disabled}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col md={4} sm={12}>
                    <DateInput
                        label="Date & time ready *"
                        name={'dateTimeReady'}
                        type={"datetime-local"}
                        control={control}
                        disabled={disabled}
                    />
                    <TextAreaInput
                        label="Free information to be passed to declarant"
                        name={'freeInformation'}
                        rows={5}
                        control={control}
                        disabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <CheckboxInput
                        containerClass={'mt-4'}
                        label="Urgent"
                        name={'urgent'}
                        control={control}
                        disabled={disabled}
                    />
                    <CheckboxInput
                        label="Anticipé"
                        name={'anticipated'}
                        control={control}
                        disabled={disabled}
                    />
                    <CheckboxInput
                        label="Multiple"
                        name={'multiple'}
                        control={control}
                        disabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <AsyncSelectInput
                        label="Port / Customs office *"
                        name='portCustomsOffice'
                        control={control}
                        namespace={'customeo_ports'}
                        normalizer={NameableNormalizer}
                        menuPlacement={'auto'}
                        getValues={getValues}
                        isDisabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <AsyncSelectInput
                        label="Exit customs office *"
                        name='exitCustomsOffice'
                        control={control}
                        namespace={'customeo_ports'}
                        normalizer={NameableNormalizer}
                        menuPlacement={'auto'}
                        getValues={getValues}
                        isDisabled={disabled}
                    />
                </Col>

                <Col md={4} sm={12}>
                    <CheckboxInput
                        label="Joindre les documents du dossier"
                        name={'attachDocuments'}
                        control={control}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            {
                disabled
                    ? null
                    : (
                        <div className="mt-4">
                            <ButtonInput
                                name="clearance"
                                setValue={setValue}
                                label={'Submit for clearance'}
                            />
                            <ButtonInput
                                name="preparation"
                                setValue={setValue}
                                label={'Submit for preparation'}
                                variant="warning"
                                className="ms-2 text-white"
                            />
                            <ButtonInput
                                name="estimation"
                                setValue={setValue}
                                label={'Submit for estimation'}
                                variant='info'
                                className="ms-2"
                            />
                        </div>
                    )
            }
        </>
    );
}
