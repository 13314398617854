import {normalizedType, Normalizer} from "./normalizer";
import {Customer} from "../../model/Customer";

const CustomerNormalizer = new class implements Normalizer<Customer>{
    normalize(data: normalizedType<Customer>): Customer {

        return new Customer(
            data.id,
            data.name
        );
    }
}();

export { CustomerNormalizer };
