import React from 'react';
import AuthLayout from './Auth/AuthLayout';
import LoginForm from '../forms/app/auth/LoginForm';
import RegisterForm from '../forms/app/auth/RegisterForm';
import BottomLinks from '../components/Auth/BottomLinks';
import ForgottenPasswordForm from '../forms/app/auth/ForgottenPasswordForm';
import {FieldValues} from "react-hook-form";
import {Alert} from "react-bootstrap";

enum FormTypes {
    LOGIN = 'login',
    REGISTER = 'register',
    FORGOTTEN_PASSWORD = 'forgotten_password'
}

type LoginLayoutProps = {
    hasRegister?: boolean
    handleLogin: (formData: FieldValues) => void
    error?: string | null | undefined
};

const LoginLayout = ({hasRegister = false, handleLogin, error}: LoginLayoutProps) => {

    const initialState: string = FormTypes.LOGIN;

    const [state, setState] = React.useState<string>(initialState);

    const handleClick = (event: any) => {
        setState(event.target.id)
    }

    const bottomLinks = [];

    switch (state) {
        case FormTypes.LOGIN : {
            bottomLinks.push(
                {'id': FormTypes.FORGOTTEN_PASSWORD, 'label': 'Mot de passe oublié ?'}
            )

            if (hasRegister) {
                bottomLinks.push(
                    {'id': FormTypes.REGISTER, 'label': 'Inscription', 'helptext': 'Vous n\'avez pas de compte ?'}
                )
                break;
            }

            break;
        }
        case FormTypes.REGISTER : {
            bottomLinks.push(
                {'id': FormTypes.LOGIN, 'label': 'Connexion', 'helptext': 'Vous avez déjà un compte ?'}
            )
            break;
        }
        case FormTypes.FORGOTTEN_PASSWORD : {
            bottomLinks.push(
                {'id': FormTypes.LOGIN, 'label': 'Connexion'}
            )
            break;
        }
        default:
            break;
    }

    return (
        <>
            <AuthLayout
                bottomLinks={
                    <BottomLinks onclick={handleClick} links={bottomLinks}/>
                }
                helpText={
                    state === FormTypes.FORGOTTEN_PASSWORD
                        ? 'Saisissez votre e-mail afin de réinitialiser votre mot de passe.'
                        : ''
                }
            >
                {
                    error ?
                        <Alert variant={'danger'} dismissible>{error}</Alert>
                        : null
                }
                {state === initialState ?
                    <LoginForm id={'login_form'} formName={'login_form'} handleLogin={handleLogin}/> :
                    state === FormTypes.REGISTER
                        ? <RegisterForm id={'register_form'} formName={'register_form'}/>
                        : <ForgottenPasswordForm id={'forgotten_password_form'}/>
                }

            </AuthLayout>
        </>
    );
};

export default LoginLayout;
