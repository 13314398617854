import {useWatch} from "react-hook-form";
import {ReactNode} from "react";

type ConditionalInputProps = {
    control: any,
    fieldName: any,
    renderField: () => ReactNode,
    fieldValue?: any,
    defaultValue?: any,
}

export default function ConditionalInput({control, fieldName, renderField, fieldValue = true}: ConditionalInputProps) {
    const value = useWatch({
        control,
        name: fieldName,
    });

    if ((value ?? false) === fieldValue) {
        return <>
            {renderField()}
        </>
    }

    return null;
}
