import {normalizedType, Normalizer, serializedType} from "./normalizer";
import {ShipmentAttachedFile} from "../../model/ShipmentAttachedFile";

const ShipmentAttachedFileNormalizer = new class implements Normalizer<ShipmentAttachedFile>{
    normalize(data: normalizedType<ShipmentAttachedFile>): ShipmentAttachedFile {
        const resource = new ShipmentAttachedFile(
            data.id,
            data.fileName
        );

        resource.attachedFile = data.attachedFile ?? null;
        resource.attachedFileMimeType = data.attachedFileMimeType ?? null;
        resource.shipmentInvoice = data.shipmentInvoice ?? null;
        resource.shipmentPacking = data.shipmentPacking ?? null;
        resource.shipmentBlLta = data.shipmentBlLta ?? null;
        resource.shipmentDocument = data.shipmentDocument ?? null;

        return resource;
    }

    serialize(data: Partial<ShipmentAttachedFile>, context: any): serializedType<ShipmentAttachedFile> {
        return {
            file: data.file,
            shipmentInvoice: data.shipmentInvoice ? `/api/shipments/${data.shipmentInvoice}` : undefined,
            shipmentPacking: data.shipmentPacking ? `/api/shipments/${data.shipmentPacking}` : undefined,
            shipmentBlLta: data.shipmentBlLta ? `/api/shipments/${data.shipmentBlLta}` : undefined,
            shipmentDocument: data.shipmentDocument ? `/api/shipments/${data.shipmentDocument}` : undefined,
        };
    }
}();

export { ShipmentAttachedFileNormalizer };