import React, {useEffect, useState} from "react";
import {Shipment} from "../../../../model/Shipment";
import useDatasource from "../../../../hooks/useDatasource";
import {ShipmentNormalizer} from "../../../../services/normalizer/shipmentNormalizer";
import axios from "axios";
import * as Dossier from "./Dossier";
import {renderFormProps} from "../../../FormController";
import {Ticket} from "../../../../model/Ticket";
import {FormActions} from "../../../../components/FormActions";

interface DossierTypeProps extends renderFormProps<Ticket> {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const DossierType = (
    props: DossierTypeProps,
) => {
    const [shipment, setShipment] = useState<Shipment | null>(null);

    const {get} = useDatasource<Shipment>('shipments', ShipmentNormalizer);
    const id = props.resource.shipment.id;
    const getValues = props.getValues;
    const reset = props.reset;
    const setLoading = props.setLoading;

    useEffect(() => {
        const controller = new AbortController();

        get(
            id, controller
        ).then(
            (res) => {
                reset({...getValues(), shipment: {...res}});

                return res;
            }
        ).then(
            setShipment
        ).then(() => {
            setLoading(false);
        }).catch(err => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        }
    }, [get, id, reset, getValues, setLoading]);

    return (<>
        <Dossier.Customer {...props} shipment={props.isLoading || !shipment ? props.resource.shipment : shipment}/>
        <Dossier.Ware {...props}/>
        <Dossier.MainTransport {...props} shipment={props.isLoading || !shipment ? props.resource.shipment : shipment}/>
        <Dossier.Unloading {...props}/>
        <Dossier.Shipping {...props}/>
        <Dossier.Billing {...props}/>
        <FormActions isLoading={props.isLoading}/>
    </>);
}

export default DossierType