export const slugify = (text: string) =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')

export function capitalize(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

// Méthode pour adapter les titres des colonnes à des noms de propriétés.
export const arrayIndexFormat = (str: string): string => {
    return str.replace(/ /g, '_').toLowerCase();
};

export const titleFormat = (str: string): string => {
    str = str.replace(/_/g, ' ');

    if(str.length < 4) return str.toUpperCase();

    return capitalize(str);
};

export const parseInlineStyle = (style: string) => {
    const template = document.createElement('template');
    template.setAttribute('style', style)
    return Object.entries(template.style)
        .filter(([ key ]) => !/^[0-9]+$/.test(key))
        .filter(([ , value ]) => Boolean(value))
        .filter(([ , value ]) => value !== 'initial')
        .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), {});
}