import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import TextInput from "../../fields/TextInput";
import React, {useEffect} from "react";
import ButtonComponent from "../../../components/ButtonComponent";
import {renderFilterProps} from "../../../components/ListingController";
import FilterFormsLayout from "./FilterFormsLayout";
import {ControlSwitchInput} from "../../../components/SwitchButton";

interface FilterProps extends renderFilterProps {}

export default function ImportFilter({onFilter, onReset, defaultValues}: FilterProps) {
    const methods = useForm({defaultValues: defaultValues});
    const {
        handleSubmit,
        reset,
        control,
        watch,
        getFieldState
    } = methods;

    const activeFilters = Object.entries(watch())
        // retire les champs qui ont leurs valeurs par défaut
        .filter(([key]) => getFieldState(key).isDirty)
    ;

    useEffect(() => {
        reset(defaultValues, {keepDefaultValues: true})
    }, [defaultValues, reset]);

    return <FilterFormsLayout
        activeFilters={activeFilters.length}
        renderForm={(toggleCollapsePanel: any) =>
            <Form onSubmit={handleSubmit(onFilter)}>

                <Row>
                    <Col lg={3}>
                        <TextInput name={"shipment+client+name"} label={"Nom client"} containerClass={"mb-3"} control={control}/>
                    </Col>
                    <Col lg={3}>
                        <TextInput name={"shipment+derudderReference"} label={"N° dossier"} containerClass={"mb-3"} control={control}/>
                    </Col>
                    <Col lg={3}>
                        <TextInput name={"shipment+trafficCode"} label={"Code trafic"} containerClass={"mb-3"} control={control}/>
                    </Col>

                    <Col lg={3}>
                        <TextInput name={"shipment+reference"} label={"Référence client"} containerClass={"mb-3"} control={control}/>
                    </Col>
                    <Col lg={3}>
                        <TextInput name={"shipment+bookingNumber"} label={"N° booking"} containerClass={"mb-3"} control={control}/>
                    </Col>
                    <Col lg={2}>
                        <ControlSwitchInput name={"shipment+archived"} control={control} label={"Afficher les dossiers archivés ?"} />
                    </Col>
                    <Col lg={2}>
                        <ControlSwitchInput name={"managedByMe"} control={control} label={"Afficher mes dossiers"} />
                    </Col>
                </Row>

                <Row className={"justify-content-end"}>
                    <Col className={"text-end"}>
                        <ButtonComponent buttonType={"submit"} buttonLabel={"Rechercher"} buttonIcon={"fas fa-search"} handleClickEvent={toggleCollapsePanel} />
                        <ButtonComponent buttonType={"button"} buttonLabel={"Réinitialiser"} buttonIcon={"fas fa-redo-alt"} classes={"btn-secondary ms-1"} handleClickEvent={() => {reset(); onReset(); toggleCollapsePanel();}}/>
                    </Col>
                </Row>

            </Form>
        }
    />
}