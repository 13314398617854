import {Form} from "react-bootstrap";
import React, {useId} from "react";
import {Control, Controller} from "react-hook-form";
import Select, {ClassNamesConfig, StylesConfig} from "react-select";
import {StateManagerProps} from "react-select/dist/declarations/src/useStateManager";

export interface SelectInputOptions {
    label: string,
    value: any,
}

export interface SelectInputProps extends Omit<StateManagerProps, 'options'> {
    /** class CSS du Form.Group */
    containerClass?: string
    label?: string,
    name: string,
    control: Control<any>,
    selectOptions: SelectInputOptions[]
    singleValueClasses?: string
    labelClassName?: string
    required?: boolean
}

const SelectInput = (props: SelectInputProps) => {
    const {
        name,
        id,
        label,
        containerClass,
        selectOptions,
        isMulti = false,
        isClearable = true,
        control,
        placeholder = props.label,
        singleValueClasses,
        labelClassName,
        required = false,
        closeMenuOnSelect = props.isMulti,
        ...selectProps
    } = props

    const classNames: ClassNamesConfig = {
        multiValue: () => 'bg-primary text-white',
        multiValueLabel: () => 'text-light',
        singleValue: () => singleValueClasses ?? ''
    };
    const styles: StylesConfig = {
        multiValueRemove: (styles: any) => ({
            ...styles,
            ':hover': {
                backgroundColor: 'transparent'
            }
        })
    };

    const localId = useId();

    const idToUse = id ?? localId

    return (
        <>
            <Form.Group className={containerClass}>
                {label ? <Form.Label className={labelClassName} htmlFor={idToUse}>{label}</Form.Label> : null}

                <Controller
                    // pas de disabled car cela empêche getValues de fonctionner
                    // https://react-hook-form.com/docs/useform/getvalues
                    // @see AsyncSelectInput.tsx
                    control={control}
                    name={name}
                    render={
                        (
                            {
                                field: {onChange, onBlur, name, value},
                                fieldState: {error},
                            }
                        ) => {
                            const valueExtractor = !isMulti
                                // on trouve une option
                                ? selectOptions.find(o => o.value === value)
                                // on trouve toutes les options
                                : selectOptions.filter(o => value ? value.indexOf(o.value) >= 0 : false);

                            const changeExtractor = !isMulti
                                ? (value: any) => onChange(value?.value)
                                : (values: any) => onChange(values.map((option: any) => option.value));

                            return (
                                <>
                                    <Select
                                        name={name}
                                        value={valueExtractor}
                                        onChange={changeExtractor}
                                        required={required}
                                        onBlur={onBlur}
                                        options={selectOptions}
                                        placeholder={placeholder}
                                        id={idToUse}
                                        classNames={classNames}
                                        styles={styles}
                                        isMulti={isMulti}
                                        isClearable={isClearable}
                                        closeMenuOnSelect={closeMenuOnSelect}
                                        {...selectProps}
                                    />
                                    {
                                        error
                                            ? (
                                                <div className="text-danger">
                                                    {error?.message}
                                                </div>
                                            ) : null
                                    }
                                </>
                            );
                        }}
                />
            </Form.Group>
        </>
    )
}

export default SelectInput;