import {Shipment} from "./Shipment";
import {CustomeoTypeConstants} from "../constants/model";
import {CustomeoCurrency} from "./CustomeoCurrency";
import {User} from "./User";
import {IRI} from "./interface/IRI";

export class ShipmentCustomeo {
    shipment: Shipment | Pick<Shipment, 'id'> | IRI | null = null
    type: CustomeoTypeConstants | null = null
    inlandCost: number | null = null
    // inlandCurrency: CustomeoCurrency | null = null
    freightCharges: number | null = null
    // freightChargesCurrency: CustomeoCurrency | null = null
    insuranceAmount: number | null = null
    // insuranceAmountCurrency: CustomeoCurrency | null = null
    dateTimeReady: Date | null = null
    aheadOfTime: boolean = false
    multiple: boolean = false
    clearance: boolean = false
    preparation: boolean = false
    estimation: boolean = false
    urgent: boolean = false
    anticipated: boolean = false
    attachDocuments: boolean = false
    insuranceAmountCurrency: CustomeoCurrency | null = null
    freightChargesCurrency: CustomeoCurrency | null = null
    inlandCurrency: CustomeoCurrency | null = null
    createdAt: Date = new Date();
    createdBy: User | null = null;
    brokerStatus: string | null = null;
    customsStatus: string | null = null;
    reference: string | null = null;
    freeInformation: string | null = null;
    inNameOf: string | null = null;
    derudderReference: string | null = null;
    clientAccount: string | null = null;
    portCustomsOffice: string | null = null;
    exitCustomsOffice: string | null = null;
    team: string | null = null;
    customeoUuid: string | null = null;
    brokerStatusStyles: string = '';
    customsStatusStyles: string = '';
    baeClearedDate: Date | null = null;

    constructor(
        public id: number | null = null
    ) {}

    get submitType() {
        switch (true) {
            case this.clearance:
                return 'clearance';
            case this.preparation:
                return 'preparation';
            case this.estimation:
                return 'estimation';
        }

        throw new Error('No submit type for custom order')
    }
}

export type ShipmentCustomeoType = ShipmentCustomeo;