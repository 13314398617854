import React, {useCallback, useContext, useState} from "react";
import useDatasource from "../../../../hooks/useDatasource";
import {FlashContext} from "../../../../context/FlashContext";
import {FormController} from "../../../../forms/FormController";
import {Ticket} from "../../../../model/Ticket";
import {TicketNormalizer} from "../../../../services/normalizer/ticketNormalizer";
import {ShipmentNormalizer} from "../../../../services/normalizer/shipmentNormalizer";
import {removeEmptyFields} from "../../../../utils";
import DossierType from "../../../../forms/app/types/Import/DossierType";

interface DossierTabProps {
    ticket: Ticket | null
}

const DossierTab = ({ticket}: DossierTabProps) => {
    const [isLoading, setLoading] = useState(true);
    const {addSuccess} = useContext(FlashContext);

    const {update: updateTicket} = useDatasource('workflow_tickets', TicketNormalizer);
    const {update: updateShipment} = useDatasource('shipments', ShipmentNormalizer)

    const callback = useCallback(
        async (data: Ticket, resource: Ticket) => {
            setLoading(true);
            data.shipment.id = resource.shipment.id;
            removeEmptyFields(data);
            removeEmptyFields(data.shipment);

            try {
                await Promise.all([
                    updateTicket(data, 'dossier'),
                    updateShipment(data.shipment, 'dossier')
                ]);

                addSuccess('Dossier modifié avec succès.');
            } catch (error) {
                console.log(error)
            }

            setLoading(false);
        }
        , [updateTicket, updateShipment, addSuccess]
    )

    return <div className={"d-flex flex-column"}>
        <FormController
            renderForm={(props) => <DossierType {...props} setLoading={setLoading} isLoading={isLoading}/>}
            resource={ticket}
            normalizer={TicketNormalizer}
            namespace={'workflow_tickets'}
            handleSubmitEvent={(resource) => (data: Ticket) => callback(data, resource)}
        />
    </div>;
};

export default DossierTab;