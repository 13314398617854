import {normalizedType, Normalizer} from "./normalizer";
import {Nameable} from "../../model/interface/Nameable";

type CustomeoTeam = Nameable & {customeoTeamId?: string}

const CustomeoTeamNormalizer = new class implements Normalizer<CustomeoTeam>{
    normalize(data: normalizedType<CustomeoTeam>): CustomeoTeam {

        return {
            id: data.id,
            customeoTeamId: data.customeoTeamId,
            name: data.name
        };
    }
}();

export { CustomeoTeamNormalizer };
