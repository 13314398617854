import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {ColumnImportConstants, TransportationModeConstants} from '../../../constants/model';
import {formatDate} from '../../../utils';
import {Shipment} from '../../../model/Shipment';
import {TicketType} from '../../../model/Ticket';
import classNames from "classnames";
import {ShipmentCustomeo} from "../../../model/ShipmentCustomeo";
import useDatasource from "../../../hooks/useDatasource";
import {ShipmentCustomeoNormalizer} from "../../../services/normalizer/ShipmentCustomeoNormalizer";
import Spinner from "../../Spinner";
import {CommentNormalizer} from "../../../services/normalizer/ShipmentCommentaireNormalizer";
import axios from "axios";

interface ShipmentProps {
    ticket: TicketType;
}

const includeDouaneBroker = [
    ColumnImportConstants.CUSTOMS,
    ColumnImportConstants.ARRIVAL
];

const getTransportationMode = (shipment: Shipment) => {
    let transportationMode: string;
    switch (shipment?.transportationMode) {
        case TransportationModeConstants.ROAD:
            transportationMode = 'Camion';
            break;
        case TransportationModeConstants.SEA:
            transportationMode = 'Navire';
            break;
        case TransportationModeConstants.AIR:
            transportationMode = 'N° de vol';
            break;
        case TransportationModeConstants.TRAIN:
            transportationMode = 'Train';
            break;
        default:
            transportationMode = '';
    }
    return transportationMode;
};

function shipmentDate(date: Date | null) {
    return date ? formatDate(date, 'fr-FR') : '';
}

function getBrokerClasses(customeo: ShipmentCustomeo): string {
    switch (true) {
        case customeo.brokerStatus?.includes('Blocked'):
            return 'bg-danger';
        default:
            return '';
    }
}

function getDouaneClasses(customeo: ShipmentCustomeo): string {
    switch (customeo.customsStatus) {
        case 'BAE : Cleared':
            return 'bg-success'
        default:
            return '';
    }
}

const ShipmentKanbanCard: React.FC<ShipmentProps> = ({ticket}) => {
    const shipment = ticket.shipment;

    const [customeo, setCustomeo] = useState<ShipmentCustomeo | null>(null);
    const [hasUnResponded, setHasUnResponded] = useState<boolean>(false);
    const {isLoading, get: getCustomeo} = useDatasource('shipment_customeos', ShipmentCustomeoNormalizer)
    const {index: findComments} = useDatasource('shipment_comments', CommentNormalizer)

    useEffect(() => {
        const controller = new AbortController();

        if (typeof shipment.customeos[0] === 'string' && (ticket.category === 'import' && includeDouaneBroker.includes(ticket.column as ColumnImportConstants))) {
            const firstCustomeoId = parseInt(shipment.customeos[0].split('/').slice(-1)[0]);
            if (!isNaN(firstCustomeoId)) {
                (async () => {
                    try {
                        setCustomeo(await getCustomeo(firstCustomeoId, controller));
                    } catch (err) {
                        if (axios.isCancel(err)) {
                            return;
                        }
                        throw err;
                    }
                })()
            } else {
                console.log('unable to extract id from :', shipment.customeos[0]);
            }
        }

        return () => {
            controller.abort();
        }
    }, [getCustomeo, shipment, ticket]);

    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            try {
                const results = await findComments({shipment: shipment.id}, {id: "DESC"}, controller)

                if (results[0]) {
                    setHasUnResponded(results[0].createdByClient)
                }

            } catch (err) {
                if (axios.isCancel(err)) {
                    return;
                }
                throw err;
            }
        })()

        return () => {
            controller.abort();
        }
    }, [findComments, setHasUnResponded, shipment]);

    const [displayedLoadingLabel, displayedLoadingDate] = shipment.displayedLoadingDate
    const [displayedUnloadingLabel, displayedUnloadingDate] = shipment.displayedUnloadingDate
    const [displayedUnloadingPredictiveLabel, displayedUnloadingPredictiveDate] = shipment.displayedUnloadingPredictiveDate

    return (
        <>
            <Row className={'align-item-center text-black'}>
                <Col className={'fs-4 p-0 fw-bold text-muted'}>
                    {shipment?.derudderReference}
                </Col>
                <Col className={'p-0 text-end'}>
                    {shipment?.containerizationType?.toUpperCase()}
                </Col>
            </Row>

            <Row className={'mb-1'}>
                <Col className={'fs-5 p-0 fw-bold text-black'}>
                    {typeof shipment?.client !== 'string' ? shipment?.client?.name : ''}
                </Col>
            </Row>

            <Row className={'small fw-bold text-black mb-1'}>
                <Col lg={'5'} className={'p-0'}>
                    {
                        shipment.loadingPlatform
                            ? `${shipment.loadingPlatform.name} (${shipment.loadingPlatform.countryCode})`
                            : 'N/A'
                    }
                </Col>
                <Col lg={'1'} className={'p-0 text-black'}>
                    {'=>'}
                </Col>
                <Col lg={'5'} className={'p-0 text-black'}>
                    {
                        shipment.unloadingPlatform
                            ? `${shipment.unloadingPlatform.name} (${shipment.unloadingPlatform.countryCode})`
                            : 'N/A'
                    }
                </Col>
            </Row>

            <Row className={'fs-6 text-black mb-2'}>
                <Col className={'p-0'}>
                    {shipment ? getTransportationMode(shipment) : null}
                </Col>
                <Col className={'p-0'}>
                    {shipment?.trafficIdentification}
                </Col>
            </Row>

            <Row className={'fs-6 fw-bold text-black mb-1'}>
                <Col className={'p-0'}>
                    {displayedLoadingLabel}: {shipmentDate(displayedLoadingDate)}
                </Col>
                <Col className={'p-0'}>
                    {displayedUnloadingLabel}: {shipmentDate(displayedUnloadingDate)}<br/>
                    {
                        !shipment.ATA && displayedUnloadingPredictiveDate
                            ? <><span className={'text-success'}>{displayedUnloadingPredictiveLabel}: {shipmentDate(displayedUnloadingPredictiveDate)}</span><br/></>
                            : null
                    }
                    {
                        !shipment.ATA && shipment.unloadingCompanyPlannedDate
                            ? <span className={'text-success'}>CIE: {shipmentDate(shipment.unloadingCompanyPlannedDate)}</span>
                            : null
                    }
                </Col>
            </Row>

            {
                ticket.category === 'import' && includeDouaneBroker.includes(ticket.column as ColumnImportConstants)
                    ? (
                        <>
                            {
                                isLoading
                                ? (<Spinner size={'xs'}/>)
                                : (<CardCustomeoStatus customeo={customeo}/>)
                            }
                        </>
                    ) : null
            }

            {
                hasUnResponded
                    ? (
                        <Row className={'fs-4 fw-bold text-warning mb-1'}>
                            <Col className={'p-0 text-end'}>
                                <i className={'fas fa-comment'}/>
                            </Col>
                        </Row>
                    )
                    : null
            }
        </>
    );
};

const CardCustomeoStatus = ({customeo}: {customeo: ShipmentCustomeo | null}) => {
    return <>
        <Row className={'fs-6 text-black mb-1'}>
            <Col className={'p-0'}>
                Broker
            </Col>
            <Col
                className={
                    classNames(
                        'p-0',
                        'text-center',
                        customeo
                            ? getBrokerClasses(customeo)
                            : null
                    )
                }
            >
                {
                    customeo?.brokerStatus
                }
            </Col>
        </Row>

        <Row className={'fs-6 text-black'}>
            <Col className={'p-0'}>
                Douane
            </Col>
            <Col
                className={
                    classNames(
                        'p-0',
                        'text-center',
                        customeo
                            ? getDouaneClasses(customeo)
                            : null
                    )
                }
            >
                {
                    customeo?.customsStatus
                }
            </Col>
        </Row>
    </>
}

export default ShipmentKanbanCard;