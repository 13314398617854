/* Shipment constants */
export enum ShipmentTypeConstants {
    IMPORT = 'import',
    EXPORT = 'export'
}
export enum TransportationModeConstants {
    AIR = 'air',
    SEA = 'mer',
    ROAD = 'route',
    TRAIN = 'train',
    RAIL = 'rail'
}

export enum TransportationTypeConstants {
    TRACTION= 'Traction',
    CONTAINER = 'Conteneur',
    COVENANT = 'Conventionnel'
}
export enum StatusConstants {
    CLOSED = 'cloturee',
    RECEIVED = 'bienrecumerci',
    IM_ON_IT = 'jesuisdessus',
    PASSED_BOOKING = 'bookingpasse',
    LETS_GO = 'cestparti',
    DELIVERED = 'cestlivre'
}

/* ShipmentCustomeo constants */
export enum CustomeoTypeConstants {
    TYPE_IMPORT = 'import',
    TYPE_EXPORT = 'export',
    TYPE_TRANSIT = 'transit',
    TYPE_T2L = 't2l',
    // ? TYPE_CLEARTRANSIT = 'cleartransit', -> cf commentaire: "non autorisé via l'API"
    // ? TYPE_CLEARCCS = 'clearccs'  -> cf commentaire: "non autorisé via l'API"
}

export enum BrokerStatusCodeConstants {
    BROKER_STATUS_CODE_BAS = 'BAS',
    BROKER_STATUS_CODE_GNR = 'GNR',
    BROKER_STATUS_CODE_NEW_BLOCKED = 'NEW-BLOCKED',
    BROKER_STATUS_CODE_NEW_LAUNCHING = 'NEW-LAUNCHING',
    BROKER_STATUS_CODE_NEW_PI = 'NEW-PI',
    BROKER_STATUS_CODE_NEW_PREP = 'NEW-PREP',
    BROKER_STATUS_CODE_NEW_PREPARED = 'NEW-PREPARED',
    BROKER_STATUS_CODE_RECTIF_WIP = 'RECTIF-WIP',
    BROKER_STATUS_CODE_BLOCKED_5D = 'BLOCKED-5D',
    BROKER_STATUS_CODE_BLOCKED_2D = 'BLOCKED-2D'
}

export enum CustomStatusCodeConstants {
    CUSTOMS_STATUS_ANN = 'ANN',
    CUSTOMS_STATUS_ANT = 'ANT',
    CUSTOMS_STATUS_BAE = 'BAE',
    CUSTOMS_STATUS_BAE_ECS = 'BAE-ECS',
    CUSTOMS_STATUS_BARCODE_AVAILABLE = 'BARCODE : Available',
    CUSTOMS_STATUS_CHECK_MODIFICATION = 'CHECK : Modification',
    CUSTOMS_STATUS_INV = 'INV',
    CUSTOMS_STATUS_EAV = 'EAV',
    CUSTOMS_STATUS_NEW = 'NEW',
    CUSTOMS_STATUS_NEW_CAN = 'NEW-CAN',
    CUSTOMS_STATUS_NEW_PREP = 'NEW-PREP',
    CUSTOMS_STATUS_TRANSIT_CL = 'TRANSIT-CL',
    CUSTOMS_STATUS_TRANSIT_DEF = 'TRANSIT-DEF',
    CUSTOMS_STATUS_TRANSIT_IS = 'TRANSIT-IS',
    CUSTOMS_STATUS_VAL = 'VAL',
}

/* Ticket constants */
export enum ColumnImportConstants {
    NEW_CASE = 'Nouveau dossier', // Nouveau Dr
    LOADING = 'Embarquement', // FOB,
    ARRIVAL = 'Arrivée',
    CUSTOMS = 'Douane',
    TRANSPORT = 'Transport',
    BILLING = 'Facturation'
}
export enum ColumnExportConstants {
    NEW_CASE = 'Nouveau dossier', // Nouveau Dr
    BOOKING = 'Booking',
    TRANSPORTATION = 'Transport',
    FILLING = 'Empotage',
    CUSTOMS = 'Douane / Documentation',
    LOADING = 'Embarquement', // FOB
    BILLING = 'Facturation / DAP - DDP'
}
export enum TicketCategoryConstants {
    CATEGORY_IMPORT = 'import',
    CATEGORY_EXPORT = 'export'
}
export enum DeliveryStatusConstants {
    INSTRUCTION_INITIATED ='Instruction initiée',
    BOOKING_SENT = 'Réservation envoyée',
    BOOKING_ACCEPTED = 'Réservation acceptée',
    TRANSPORT_ORDER_SENT = 'Commande transport envoyée',
    LOADING_ORDER_SENT = 'Commande chargement envoyée',
    UNLOADING_ORDER_SENT = 'Commande de dépotage envoyée',
    DELIVERY_DONE = 'Avis livraison effectuée',
    TRANSPORT_RETRIEVED = 'Transport enlevé',
    DELIVERY_CONFIRMED = 'Livraison confirmée'
}
export enum ContainerDeliveryStatusConstants {
    INSTRUCTION_INITIATED ='Instruction initiée',
    BOOKING_SENT = 'Réservation envoyée',
    BOOKING_ACCEPTED = 'Réservation acceptée',
    TRANSPORT_ORDER_SENT = 'Commande transport envoyée',
    DELIVERY_DONE = 'Avis livraison effectuée',
    TRANSPORT_RETRIEVED = 'Transport enlevé',
    DELIVERY_CONFIRMED = 'Livraison confirmée'
}
export enum CovenantDeliveryStatusConstants {
    INSTRUCTION_INITIATED ='Instruction initiée',
    BOOKING_SENT = 'Réservation envoyée',
    BOOKING_ACCEPTED = 'Réservation acceptée',
    TRANSPORT_ORDER_SENT = 'Commande transport envoyée',
    LOADING_ORDER_SENT = 'Commande chargement envoyée',
    DELIVERY_DONE = 'Avis livraison effectuée',
    TRANSPORT_RETRIEVED = 'Transport enlevé',
    DELIVERY_CONFIRMED = 'Livraison confirmée'
}
export enum TractionDeliveryStatusConstants {
    INSTRUCTION_INITIATED ='Instruction initiée',
    BOOKING_SENT = 'Réservation envoyée',
    BOOKING_ACCEPTED = 'Réservation acceptée',
    TRANSPORT_ORDER_SENT = 'Commande transport envoyée',
    UNLOADING_ORDER_SENT = 'Commande de dépotage envoyée',
    DELIVERY_DONE = 'Avis livraison effectuée',
    TRANSPORT_RETRIEVED = 'Transport enlevé',
    DELIVERY_CONFIRMED = 'Livraison confirmée'
}
export enum ShipmentEntrustWithConstants {
    PRE_DELIVERY = 'pre_acheminement',
    FREIGHT = 'fret_transport',
    CUSTOMS = 'douane_depart',
    POST_DELIVERY = 'post_acheminement',
    ARRIVAL_CUSTOMS = 'douane_arrivee',
}
export enum ShipmentPaletteSizeConstants {
    SIZE_100x120 = '100x120',
    SIZE_120x120 = '120x120',
    SIZE_80x120 = '80x120',
    SIZE_80x100 = '80x100',
    SIZE_114x114 = '114x114'
}
export enum ShipmentPaletteTypeConstants {
    LOST = 'Perdues',
    EXCHANGE = 'Echange',
    HACHETTE = 'Hachette',
    NONE = 'Aucun'
}
export enum ShipmentIncotermsConstants {
    EXW = 'EXW',
    FOB = 'FOB',
    CIF = 'CIF',
    DAP = 'DAP',
    FCA = 'FCA',
    FAS = 'FAS',
    CFR = 'CFR',
    CPT = 'CPT',
    CIP = 'CIP',
    DPU = 'DPU',
    DDP = 'DDP'
}
