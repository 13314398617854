import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Panel from './Panel/Panel';
import LeftSidebar from './Header/LeftSideBar';
import RightSideBar from './Header/RightSideBar';

type Props = {
    children: React.ReactNode;
    handleLogout: () => void;
}

function TriptychLayout({children, handleLogout}: Props) {

    const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(false);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);
        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.add('sidebar-enable');
            } else {
                document.body.classList.remove('sidebar-enable');
            }
        }
    };

    return (
        <div className="vh-100 d-flex flex-column"
        >
            <Header openLeftMenuCallBack={openMenu}
                    handleLogout={handleLogout}/>
            <div className="position-relative bg-transparent d-flex flex-fill overflow-hidden">
                <LeftSidebar/>
                <div
                    className={'bg-transparent d-flex flex-column flex-fill px-2 pb-2 pt-0 overflow-hidden'}>
                    <Panel>
                        {children}
                    </Panel>
                </div>
                <RightSideBar/>
            </div>
            <Footer/>
        </div>
    );
}

export default TriptychLayout;
