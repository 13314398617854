import React from "react";
import CollapsiblePanel from "../../../components/CollapsiblePanel";

interface FilterFormsLayoutProps {
    activeFilters: number
    renderForm: any
}

const FilterFormsLayout = ({activeFilters, renderForm}: FilterFormsLayoutProps) => {
    const [collapsePanel, setCollapsePanel] = React.useState<boolean>(true)

    const getAmountOfActiveFilters = () => {
        if(activeFilters === 1) {
            return ': ' + activeFilters + ' filtre actif';
        } else if(activeFilters > 1) {
            return ': ' + activeFilters + ' filtres actifs';
        } else {
            return '';
        }
    }

    const title = 'Recherche ' + getAmountOfActiveFilters();

    const toggleCollapsePanel = () => {
        collapsePanel ? setCollapsePanel(false) : setCollapsePanel(true)
    }

    return (
        <CollapsiblePanel
            accordionItems={[
                {
                    header: title,
                    children: renderForm(() => toggleCollapsePanel()),
                    headingIcon: "fas fa-search",
                    collapse: collapsePanel
                }
            ]}
        />
    );
}

export default FilterFormsLayout;