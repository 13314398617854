import React from 'react';
import {Form, FormControlProps} from 'react-bootstrap';
import {Control, Controller} from "react-hook-form";
import moment from "moment";

interface DateInputProps extends FormControlProps {
    containerClass?: string,
    inputClasses?: string,
    labelClasses?: string,
    label?: string,
    name: string,
    placeholder?: string,
    control: Control
    type: "date" | "datetime-local"
}

const DateInput = (props: DateInputProps) => {

    const {
        name,
        label,
        containerClass,
        inputClasses,
        labelClasses,
        placeholder,
        control,
        type,
        ...inputProps
    } = props

    return (
        <Form.Group className={containerClass}>
            {label ? <Form.Label className={labelClasses}>{label}</Form.Label> : null}

            <Controller
                control={control}
                name={name}
                disabled={props.disabled}
                render={
                    (
                        {
                            field: {onChange, name, value},
                            fieldState: {error},
                        }
                    ) => {
                        return (
                            <>
                                <Form.Control
                                    // "datetime-local" pour avoir l'heure, sinon "date"
                                    type={type}
                                    placeholder={placeholder}
                                    name={name}
                                    className={inputClasses}
                                    value={value ? moment(value).format(type === 'date' ? 'yyyy-MM-DD' : 'yyyy-MM-DDTHH:mm') : ''}
                                    onChange={onChange}
                                    {...inputProps}
                                />
                                {
                                    error
                                        ? (
                                            <div className="text-danger">
                                                {error?.message}
                                            </div>
                                        ) : null
                                }
                            </>

                        );
                    }
                }
            />
        </Form.Group>
    );
};

export default DateInput;