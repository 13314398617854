
export class Invoice {
    id: number = 0
    client: string | null = null
    accountingDocumentNumber: string | null = null
    invoiceDate: Date | null = new Date()
    dueDate: Date | null = new Date()
    caseNumber: string | null = null
    accountingDocumentCurrency: string | null = null
    invoiceAmountWithTax: number | null = 0
    creditNoteAmountWithTax: number | null = 0
    lettered: string | null = null
    taxAmount: number | null = 0
    amountExpired60j: number | null = 0
    amountExpired15j: number | null = 0
    amountExpired0j: number | null = 0
    amountBeDue0j: number | null = 0
    amountBeDue10j: number | null = 0
    amountBeDue60j: number | null = 0
    state: string | null = null
    comments: string[] = []
    attachedFiles: string[] = []
    lines: Array<{}> = [{}]
    includingCustomsLiquidation: string | null = null
    dunnings: string[] = []
    pdfs: string [] = []
    clientReference: string | null = null
    calcul: boolean = true
    inDispute: boolean = true
    valid: boolean = true
    name: string | null = null

    constructor(id: number, name: string | null, invoiceDate: Date | null) {
        this.id = id;
        this.name = name;
        this.invoiceDate = invoiceDate;
    }
}

export type InvoiceType = Invoice;