import {createContext, ReactNode, useState} from "react";

export type FlashType = {
    type: "success" | "warning" | "danger"
    body: string
}

export interface FlashContextInterface {
    flashes: FlashType[],
    addSuccess: (body: string) => void,
    addError: (body: string) => void,
    clearFlash: () => void,
    removeFlash: (index: number) => void,
}

const initialState: FlashContextInterface = {
    flashes: [],
    addSuccess: () => {},
    addError: () => {},
    clearFlash: () => {},
    removeFlash: () => {},
};

export const FlashContext = createContext<FlashContextInterface>(initialState);

export const FlashContextProvider = ({children}: {children: ReactNode}) => {

    const [state, setState] = useState<FlashContextInterface>(initialState);

    const value: FlashContextInterface = {
        flashes: state.flashes,
        addSuccess: (body: string) => {
            setState({
                ...state,
                flashes: [...state.flashes, {type: "success", body: body}],
            })

            setTimeout(value.clearFlash, 5000);
        },
        addError: (body: string) => {
            setState((prevState) => ({
                ...prevState,
                flashes: [...prevState.flashes, {type: "danger", body: body}],
            }))
        },
        clearFlash: () => {
            setState({
                ...state,
                flashes: [],
            })
        },
        removeFlash: (index) => {
            setState((prevState) => ({
                ...prevState,
                flashes: prevState.flashes.toSpliced(index, 1),
            }))
        },
    };

    return <FlashContext.Provider value={value} children={children}/>
}