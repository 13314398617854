import {Alert, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {
    ContainerDeliveryStatusConstants,
    CovenantDeliveryStatusConstants,
    TractionDeliveryStatusConstants,
    TransportationTypeConstants,
} from '../../../../../constants/model';
import {ControlSwitchInput} from '../../../../../components/SwitchButton';
import React, {useEffect, useState} from 'react';
import {formatDate} from '../../../../../utils';
import {Ticket} from '../../../../../model/Ticket';
import {renderFormProps} from "../../../../FormController";
import {Commande} from "../../../../../model/Commande";
import useDatasource from "../../../../../hooks/useDatasource";
import {CommandeNormalizer} from "../../../../../services/normalizer/CommandeNormalizer";
import ConditionalInput from "../../../../fields/ConditionalInput/ConditionalInput";
import axios from "axios";

interface ShippingProps extends renderFormProps<Ticket> {
    resource: Ticket;
}

const orderTypeContainerStatuses = Object.entries(ContainerDeliveryStatusConstants).map(([, value]) => value);
const orderTypeCovenentStatuses = Object.entries(CovenantDeliveryStatusConstants).map(([, value]) => value);
const orderTypeTractionStatuses = Object.entries(TractionDeliveryStatusConstants).map(([, value]) => value);

const ProgressBar = (resource: Commande) => {

    const statusList = (
        resource.transportType === TransportationTypeConstants.COVENANT
            ? orderTypeCovenentStatuses
            : resource.transportType === TransportationTypeConstants.TRACTION
                ? orderTypeTractionStatuses
                : resource.transportType === TransportationTypeConstants.CONTAINER
                    ? orderTypeContainerStatuses
                    : []
    );

    const isStatusPending = (index: number) => {
        // @ts-ignore
        return index > statusList.indexOf(resource.statusWorkflow);
    };

    return statusList
        .map((status, index) => {
            const bg = isStatusPending(index)
                ? 'bg-danger'
                : 'bg-success';

            return (
                <div
                    key={'status-bar-' + index}
                    style={{
                        width: '56px',
                        height: '14px',
                        marginRight: '0.1rem',
                        cursor: 'pointer',
                    }}
                    className={`popover-trigger relative p-0 ${bg}`}
                >
                    <p className={'popover absolute start-50 w-auto mb-0 mt-4'}>
                        <span className={'bg-light px-2 py-1 border'}>{status}</span>
                    </p>
                </div>
            );
        });
};

const ismoLink = (order: Commande, ref: string) => {
    const baseLink = 'https://ismo.derudder.fr/';

    switch (order.transportType) {
        case TransportationTypeConstants.TRACTION:
            return baseLink + 'commande-transport-traction/?criteria_traction[numeroDossier]=' + ref;
        case TransportationTypeConstants.CONTAINER:
            return baseLink + 'commande-transport/?criteria[numeroDossier]=' + ref;
        case TransportationTypeConstants.COVENANT:
            return baseLink + 'commande-transport-conventionnel/?criteria_conventionnel[numeroDossier]=' + ref;
        default:
            return '';
    }
};

const CancelCrossSvg = () => {
    return (

        <div style={{width: '80px'}}>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        <span className={'bg-light px-2 py-1 border'}>Commande annulée</span>
                    </Tooltip>
                }>
                <svg
                    width="18px"
                    viewBox="0 0 122.88 122.88"
                    fill="#000000"
                    fillRule="evenodd"

                >
                    <path
                        className="cls-1"
                        d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z"
                    />
                </svg>
            </OverlayTrigger>

        </div>
    );
};

export const Shipping = ({resource, control, register}: ShippingProps) => {
    const shipment = resource.shipment;

    const [orders, setOrders] = useState<Commande[]>([]);
    const {index} = useDatasource<Commande>('orders', CommandeNormalizer);

    useEffect(() => {
        if (!resource.shipment.derudderReference) {
            return
        }
        const controller = new AbortController();

        index(
            {shipment: resource.shipment.derudderReference}, undefined, controller
        ).then(
            setOrders
        ).catch(err => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        };
    }, [index, resource]);

    return (
        <Row className={'border-bottom ms-0 me-0 pt-3 pb-5'}>
            <Col>
                <h2 className={'d-flex align-items-center'}>
                    <span>Livraison</span>
                    <ControlSwitchInput
                        name={'shipment.delivery'}
                        control={control}
                        value={shipment.delivery}
                        style={{display: 'inline-block', scale: '0.5'}}
                    />
                </h2>

                <ConditionalInput
                    control={control}
                    fieldName={'shipment.delivery'}
                    renderField={() => {
                        return (
                            <>
                                {
                                    orders.map((order) => (
                                        <Row key={order.id} className={'align-items-center mb-2'}>
                                            <Col lg={6}>
                                                <p className={'mb-0 font-italic'} style={{
                                                    fontWeight: 'italic',
                                                }}>
                                                </p>
                                                <p className={'mb-0'} style={{fontSize: '12px'}}>
                                                    {
                                                        order.date && order.calculatedTime
                                                            ? (
                                                                <span>Le&nbsp;{formatDate(
                                                                    order.date,
                                                                    'fr-FR',
                                                                    {},
                                                                    'date',
                                                                )}&nbsp;à&nbsp;{formatDate(
                                                                    order.calculatedTime,
                                                                    'fr-FR',
                                                                    {},
                                                                    'time',
                                                                )}&nbsp;</span>
                                                            ) : (
                                                                <span>N/A&nbsp;&nbsp;</span>
                                                            )
                                                    }
                                                    {
                                                        order.fromLocation
                                                            ? (<span>{order.fromLocation ?? 'N/A'}&nbsp;-&nbsp;</span>)
                                                            : (<span>N/A&nbsp;-&nbsp;</span>)
                                                    }
                                                    {
                                                        order.toLocation
                                                            ? (<span>{order.toLocation ?? 'N/A'}&nbsp;</span>)
                                                            : (<span>N/A&nbsp;</span>)
                                                    }
                                                    {
                                                        order.carrier
                                                            ? (<span>|&nbsp;{order.carrier.name ?? 'N/A'}</span>)
                                                            : (<span>N/A&nbsp;</span>)
                                                    }
                                                </p>

                                            </Col>
                                            <Col lg={4}>
                                                <Row
                                                    className={'justify-content-start align-items-center d-flex'}>
                                                    <div className={'d-flex align-items-center'}>
                                                        {ProgressBar(order)}
                                                        {order.canceled
                                                            ? <CancelCrossSvg/>
                                                            : null}
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col lg={2}>
                                                <a className={'btn btn-primary ms-5'}
                                                   href={ismoLink(
                                                       order,
                                                       shipment.derudderReference?.match(/[0-9]+$/g)?.join('') || ''
                                                   )}
                                                   target="_blank"
                                                   rel="noreferrer">
                                                    <i className="fas fa-eye"></i>
                                                    <span className={'ps-1'}>
                                                        Commande
                                                    </span>
                                                </a>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                {
                                    orders.length === 0
                                        ? <Alert variant={"info"}>Aucun Résultat</Alert>
                                        : null
                                }
                            </>
                        );
                    }}
                />
            </Col>
        </Row>
    );
};
