import {Alert, Col, Row} from "react-bootstrap";
import {formatDate} from "../../../../../utils";
import AbstractComment from "../../../../../model/AbstractComment";
import {renderListingProps} from "../../../../../components/ListingController";

interface CommentProps<ResourceT extends AbstractComment> extends renderListingProps<ResourceT> {}

export function WorkflowComment<ResourceT extends AbstractComment> ({resources}: CommentProps<ResourceT>) {

    return (
        <>
            {
                resources.length === 0
                    ? (<Alert variant={"info"}>Aucune note interne.</Alert>)
                    : resources.map(({id, comment, createdAt, createdBy}) => {
                        const date = formatDate(createdAt, 'fr-FR', {}, 'date');
                        const time = formatDate(createdAt, 'fr-FR', {}, 'time');

                        return (
                            <Row className={"mb-3"} key={id}>
                                <Col>
                                    <p className={"mb-2 fs-6"}>
                                        {
                                            `Le ${date} 
                                            à ${time}  
                                            ${createdBy?.firstname} ${createdBy?.lastname} a écrit :`
                                        }
                                    </p>
                                    <p className={'pe-2 white-space-pre-wrap'}>
                                        {comment}
                                    </p>
                                </Col>
                            </Row>
                        );
                    })
            }
        </>
    )
}