import React from 'react';
import {UseFormSetValue} from "react-hook-form";
import {Button, ButtonProps} from "react-bootstrap";

interface ButtonInputProps extends Omit<ButtonProps, 'onClick' | 'type'> {
    label?: string,
    name: string,
    setValue: UseFormSetValue<any>
}

export default function ButtonInput(props: ButtonInputProps) {

    const {
        name,
        label = props.name,
        setValue,
        ...btnProps
    } = props
    return (
        <Button
            type='submit'
            onClick={() => {
                setValue(name, true);
            }}
            {...btnProps}
        >
            {label}
        </Button>
    );
}
