const formatDate = (
    date: Date | null,
    locale: string,
    options: any = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit"
    },
    format: "date" | "time" = 'date'
) => {
    if (date === null) return '';

    if (format === 'time') {
        let formatedDate = date.toLocaleString().split(' ')[1].split(':').splice(0, 2);
        return formatedDate.join(':');
    }

    return new Intl.DateTimeFormat(locale, options).format(date);
};

const randomDate = (start: Date, end: Date) => {
    return new Date(start.getTime() + Math.random() *
        (end.getTime() - start.getTime()));
};

export {formatDate, randomDate};