import * as React from 'react';
import {ReactNode, useCallback, useContext, useRef} from 'react';
import {FileResource} from "../../../model/interface/FileResource";
import {TokenContext} from "../../../context/TokenContext";
import axios from "axios";
import {Button} from "react-bootstrap";

export type FileDownloadProps<ResourceT extends FileResource> = {
    children?: ReactNode | undefined
    file: ResourceT
};

export function ProtectedFileDownload<ResourceT extends FileResource>({file, children}: FileDownloadProps<ResourceT>) {

    const {token} = useContext(TokenContext);

    const memoryFileUrl = useRef('');

    const download = useCallback(async () => {
        let fileUrl = memoryFileUrl.current;
        if (!memoryFileUrl.current) {
            const url = process.env.REACT_APP_API_URL;

            const headers = {
                Accept: 'application/json',
                Authorization: 'Token ' + token,
            };

            const result = await axios.get<Blob>(`${url}${file.url}`, {
                headers: headers,
                responseType: 'blob'
            });

            fileUrl = window.URL.createObjectURL(result.data);
            memoryFileUrl.current = fileUrl
        }

        const a = document.createElement("a")


        a.download = file.name;
        a.href = fileUrl;

        a.click()

    }, [file, token]);

    return (
        <Button onClick={download} variant={'link'}>
            {children}
        </Button>
    );
}