import {Identifiable} from "./interface/Identifiable";

export enum Roles {

    APP_USER= 'APP_USER',
    SIQUAL_USER_SHOW= 'SIQUAL_USER_SHOW',
    ROLE_WORKFLOW_IMPORT_SHOW = 'ROLE_WORKFLOW_IMPORT_SHOW',
    ROLE_WORKFLOW_IMPORT_UPDATE = 'ROLE_WORKFLOW_IMPORT_UPDATE',
    ROLE_WORKFLOW_USER_SHOW = 'ROLE_WORKFLOW_USER_SHOW',
    ROLE_WORKFLOW_USER_CREATE = 'ROLE_WORKFLOW_USER_CREATE',
    ROLE_WORKFLOW_USER_UPDATE = 'ROLE_WORKFLOW_USER_UPDATE',
    ROLE_WORKFLOW_USER_DELETE = 'ROLE_WORKFLOW_USER_DELETE',
    ROLE_WORKFLOW_ACCESS_PROFIL_SHOW = 'ROLE_WORKFLOW_ACCESS_PROFIL_SHOW',
    ROLE_WORKFLOW_ACCESS_PROFIL_CREATE = 'ROLE_WORKFLOW_ACCESS_PROFIL_CREATE',
    ROLE_WORKFLOW_ACCESS_PROFIL_UPDATE = 'ROLE_WORKFLOW_ACCESS_PROFIL_UPDATE',
    ROLE_WORKFLOW_ACCESS_PROFIL_DELETE = 'ROLE_WORKFLOW_ACCESS_PROFIL_DELETE',
}

export enum Profiles {
    ROLE_SUPER_ADMIN = 'Super Administrateur',
    ROLE_ADMIN = 'Administrateur',
    ROLE_USER = 'Utilisateur',
}

export class Group implements Identifiable {

    constructor(
        readonly id: number,
        public name: string,
        public roles: Array<Roles | string>,
    ) {
    }
}
