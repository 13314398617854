import {
    Control,
    FieldErrors,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue,
    UseFormWatch
} from "react-hook-form";
import React, {ReactNode, useEffect, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {Identifiable} from "../model/interface/Identifiable";
import useDatasource, {ApiViolation} from "../hooks/useDatasource";
import {Normalizer} from "../services/normalizer/normalizer";
import Spinner from "../components/Spinner";
import {SubmitHandler} from "react-hook-form/dist/types/form";
import axios from "axios";

export type renderFormProps<ResourceT extends Identifiable> = {
    register: UseFormRegister<any>
    control: Control<any>
    errors: FieldErrors
    reset: UseFormReset<any>
    resource: ResourceT
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    isLoading: boolean,
    getValues: UseFormGetValues<any>
}

type FormControllerProps<ResourceT extends Identifiable> = {
    renderForm: (props: renderFormProps<ResourceT>) => ReactNode
    resetOnSubmit?: boolean,
    handleSubmitEvent?: (previousResource: ResourceT) => SubmitHandler<ResourceT>,
    namespace: string,
    parentErrors?: ApiViolation<ResourceT>[]
    normalizer: Normalizer<ResourceT>
    id?: Identifiable["id"] | null
    resource?: ResourceT | null
    newResourceCreator?: () => ResourceT
}


export function FormController<ResourceT extends Identifiable>(props: FormControllerProps<ResourceT>) {

    const {
        renderForm,
        resetOnSubmit = false,
        handleSubmitEvent,
        namespace,
        normalizer,
        id,
        newResourceCreator,
        resource: res,
        parentErrors = []
    } = props

    const [resource, setResource] = useState<ResourceT | null>(null);

    const {get, isLoading} = useDatasource<ResourceT>(namespace, normalizer);

    const methods = useForm<ResourceT>({});
    const {
        handleSubmit,
        register,
        control,
        formState: {errors},
        reset,
        watch,
        setValue,
        getValues,
        setError,
    } = methods;

    useEffect(() => {
        parentErrors.forEach(
            ({propertyPath, message}) => {
                setError(propertyPath, {message, type: 'server'});
            }
        )
    }, [parentErrors, setError]);

    useEffect(() => {
        if (typeof res !== 'undefined') {
            setResource(res);
            if (res) {
                reset(res);
            }
            return;
        }
        const controller = new AbortController();

        if (id) {
            get(id, controller).then(el => {
                setResource(el);
                reset(el);
            }).catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }
                throw err;
            });
        } else {
            if (newResourceCreator) {
                setResource(newResourceCreator())
            } else {
                console.warn(`missing newResourceCreator for FormController ${namespace}`)
            }
        }

        return () => {
            controller.abort();
        }
    }, [get, id, reset, newResourceCreator, namespace, res])

    if (isLoading || !resource) {
        return <Spinner/>
    }

    const formFields = renderForm({register, control, errors, reset, resource, watch, setValue, isLoading, getValues});

    return <Form onSubmit={handleSubmit((data, event) => {
        if (handleSubmitEvent) {
            handleSubmitEvent(resource)(data, event)
        }

        if (resetOnSubmit) {
            reset();
        }
    })}>
        {
            Object.entries(errors).map(([key, error]) => {
                if (!error || typeof error.ref !== "undefined") {
                    return null
                }
                return (
                    <Alert variant="danger" key={key}>
                        Erreur "{key}" : {error.message?.toString()}
                    </Alert>
                )
            })
        }
        {formFields}
    </Form>
}
