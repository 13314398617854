import React, {useContext} from "react";
import {FlashContext} from "../context/FlashContext";
import {Alert} from "react-bootstrap";

const AlertHandler = () => {
    const {flashes, removeFlash} = useContext(FlashContext);

    return <>
        {
            flashes.map(
                (flash, index) => <Alert
                    key={index}
                    variant={flash.type}
                    onClose={() => {
                        removeFlash(index);
                    }}
                    dismissible
                >
                    {flash.body}
                </Alert>
            )
        }
    </>
};

export default AlertHandler;